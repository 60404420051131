import { takeLatest, fork } from 'redux-saga/effects';
import geoplaceSlice, {
  FETCH_GEOPLACES,
  DELETE_GEOPLACE,
  UPDATE_GEOPLACE,
  ADD_GEOPLACE,
} from './slice';
import schema from './schema';
import { selectGeoplaces } from './selectors';
import entityRequest from 'store/entities/xEntityRequest';
import watchDomains from './domains';

const actions = geoplaceSlice.actions;

function* watchFetch() {
  yield takeLatest(FETCH_GEOPLACES.type, entityRequest, {
    actions,
    cacheSelector: selectGeoplaces,
    schema,
  });
}

function* watchDelete() {
  yield takeLatest(DELETE_GEOPLACE.type, entityRequest, { actions });
}

function* watchUpdate() {
  yield takeLatest(UPDATE_GEOPLACE.type, entityRequest, { actions });
}

function* watchAdd() {
  yield takeLatest(ADD_GEOPLACE.type, entityRequest, { actions });
}

export default function* geoplaceSaga() {
  yield fork(watchFetch);
  yield fork(watchUpdate);
  yield fork(watchDelete);
  yield fork(watchAdd);

  yield fork(watchDomains);
}
