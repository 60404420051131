import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { feedbackIntegration, setUser } from "@sentry/react";
import { useSelector } from "react-redux";
import { selectMyUser } from "store/account/selectors";

const feedbackWidgetConfig = {
  autoInject: false,
  colorScheme: "system",
  isNameRequired: true,
  isEmailRequired: true,
  showBranding: false,
  useSentryUser: {
    name: "fullName",
    email: "email",
  },
  themeLight: {
    accentBackground: "rgb(0, 169, 224, 1)",
  },
  themeDark: {
    accentBackground: "rgb(14, 75, 149, 1)",
  },
  triggerLabel: "",
  submitButtonLabel: "Send Report",
  formTitle: "Report Feedback",
  messagePlaceholder:
    "Submit bug reports or feature requests here. We'll reach out once the issue is resolved or feature implemented. For urgent service support, use the contact support button in the menu. Thank you for your feedback!",
};

const useSentryFeedbackWidget = () => {
  const user = useSelector(selectMyUser);
  const location = useLocation();
  const widgetRef = useRef(null);

  useEffect(() => {
    if (user?.uid) {
      setUser({
        fullName: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });
    }
  }, [user]);

  useEffect(() => {
    const feedback = feedbackIntegration(feedbackWidgetConfig);

    const shouldShowWidget = () => {
      return (
        location.pathname !== "/login" &&
        !location.pathname.startsWith("/stepper/")
      );
    };

    if (shouldShowWidget()) {
      if (!widgetRef.current) {
        widgetRef.current = feedback.createWidget();
      }
    } else {
      if (widgetRef.current) {
        widgetRef.current.removeFromDom();
        widgetRef.current = null;
      }
    }

    return () => {
      if (widgetRef.current) {
        widgetRef.current.removeFromDom();
        widgetRef.current = null;
      }
    };
  }, [location.pathname]);
};

export default useSentryFeedbackWidget;
