import languageOptions from "components/Select/options/languageOptions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useQuery from "./useQuery";
import accountSlice from "store/account/slice";

export default function useLanguageFromQuery() {
  const query = useQuery();
  const dispatch = useDispatch();
  const { setLanguage } = accountSlice.actions;
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (init) return;

    setInit(true);

    const languageValue = query.get("language");
    const languageOption = languageOptions.find(
      (o) => o.value === languageValue
    );

    if (!languageOption) return;

    const { value, label } = languageOption;

    localStorage.setItem("language", value);
    dispatch(
      setLanguage({
        label,
        value,
      })
    );
  }, [query, dispatch, setLanguage, init]);

  return null;
}
