import { createSlice, createAction } from "@reduxjs/toolkit";
import { defaultEntityReducers, defaultEntityState } from "store/defaults";
import purge from "store/purge";

export default createSlice({
  name: "GEOPLACES_ENTITY",
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;
      state.data =
        payload && payload.entities ? payload.entities.geoplaces : state.data;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_GEOPLACES = createAction("FETCH_GEOPLACES");
export const DELETE_GEOPLACE = createAction("DELETE_GEOPLACE");
export const ADD_GEOPLACE = createAction("ADD_GEOPLACE");
export const UPDATE_GEOPLACE = createAction("UPDATE_GEOPLACE");
