import { createSelector } from '@reduxjs/toolkit';
import { emptyObj } from 'utilities/empties';

export const selectDevices = state => state.entities.devices.data;
export const selectIsLoadingDevices = state => state.entities.devices.isLoading;

export const buildSelectDeviceById = did =>
  createSelector([selectDevices], devices =>
    devices && did ? devices[did] || emptyObj : emptyObj
  );
