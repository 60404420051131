import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'store/account/selectors';
import accessDeep from 'utilities/object/accessDeep';

export default function useHasPermission(check) {
  const permissions = useSelector(selectPermissions);
  const hasPermission = useMemo(() => {
    if (!check) return true;

    const keys =
      typeof check === 'string'
        ? [check]
        : Array.isArray(check)
        ? check
        : Array.isArray(check.permissions)
        ? check.permissions
        : [check.permissions];

    const result = keys.map((key) => {
      return accessDeep(permissions, key);
    });

    if (check.any) {
      return result.some((val) => !!val);
    } else {
      return result.every((val) => !!val);
    }
  }, [permissions, check]);

  return hasPermission;
}
