import { takeLatest } from 'redux-saga/effects';
import assetsSlice from '../../slice';
import assetsStatusSlice, { FETCH_ASSETS_STATUS } from './slice';
import schema from '../../schema';
import { selectAssetsStatusIds } from './selectors';
import requestSaga from 'store/entities/xEntityRequest';

const actions = {
  ...assetsSlice.actions,
  ...assetsStatusSlice.actions,
  success: assetsSlice.actions.successAssetsStatus,
};

export default function* watchFetch() {
  yield takeLatest(FETCH_ASSETS_STATUS.type, requestSaga, {
    cacheSelector: selectAssetsStatusIds,
    schema,
    // NOTE: remove fetching to prevent listeners of asset loading state from being triggered
    actions,
  });
}
