export default {
  fetching: (state, { payload }) => {
    state.isLoading[payload] = true;
  },

  error: (state, { payload }) => {
    state.isLoading = {};
    state.error = payload;
  },
};
