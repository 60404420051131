import { createTheme } from "@mui/material/styles";

const customMuiTheme = createTheme({
  palette: {
    primary: {
      light: "#3581BD", // Lighter shade of Fleetbridge blue
      main: "#004F89", // Fleetbridge blue
      contrastText: "#fff",
    },
    secondary: {
      light: "#4DB6AC",
      main: "#009688",
      dark: "#00796B",
      contrastText: "#fff",
    },
    grey: {
      50: "#F9FAFB",
      100: "#F3F4F6",
      200: "#E5E7EB",
      300: "#D1D5DB",
      400: "#9CA3AF",
      500: "#6B7280",
      600: "#4B5563",
      700: "#374151",
      800: "#1F2937",
      900: "#111827",
    },
    background: {
      default: "#F9FAFB", // Light grey background
      paper: "#FFFFFF",
    },
  },
});

export default customMuiTheme;
