import { takeLatest, put } from "redux-saga/effects";
import { getStripeInvoice } from "resources/services/stripeService";
import slice, { FETCH_LATEST_INVOICE } from "../slice";

function* fetch({ payload }) {
  const { id } = payload;
  const { setLatestInvoice, error, success, fetching } = slice.actions;

  yield put(fetching());

  try {
    const stripeInvoice = yield getStripeInvoice(id);

    yield put(setLatestInvoice(stripeInvoice));
    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchUpdateCustomer() {
  yield takeLatest(FETCH_LATEST_INVOICE.type, fetch);
}
