import { takeLatest, put } from "redux-saga/effects";
import accountSlice, { UPDATE_TOKEN } from "../slice";
import jwtDecode from "jwt-decode";

function* update({ payload: { reqFn, onSuccess } }) {
  const { error, setExpiresAt, setPermissions, setAccessToken } =
    accountSlice.actions;

  try {
    const response = yield reqFn();

    const { access_token, expires_in } = response;
    yield put(setAccessToken(access_token));

    const expiresAt = JSON.stringify(expires_in * 1000 + new Date().getTime());
    yield put(setExpiresAt(expiresAt));

    const decodedToken = jwtDecode(access_token);
    const permissionsObject = JSON.parse(decodedToken.permissions);

    yield put(setPermissions(permissionsObject));

    onSuccess && onSuccess(response);
  } catch (e) {
    yield put(error(e?.message));
  }
}

export default function* watchUpdateToken() {
  yield takeLatest(UPDATE_TOKEN.type, update);
}
