import { takeLatest, put } from "redux-saga/effects";
import accountSlice, { REJECT_TRACKING_TERMS } from "store/account/slice";
import { rejectTrackingDeviceAgreement } from "resources/services/userService";

function* fetch({ payload, meta }) {
  const { uid, url } = payload;
  const { error, success } = accountSlice.actions;
  try {
    const response = yield rejectTrackingDeviceAgreement(uid, url);
    meta?.onSuccess && meta.onSuccess(response);

    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchRejectTrackingTerms() {
  yield takeLatest(REJECT_TRACKING_TERMS.type, fetch);
}
