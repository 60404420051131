import React from "react";
import { store } from "configurations/redux";
import { Provider as ReduxProvider } from "react-redux";
import { Elements as StripeElementsProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ThemeProvider } from "styled-components/macro";
import { STRIPE_KEY } from "configurations/appConfig";
import { bootstrap } from "styles/theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import MapContext from "resources/contexts/mapContext";
import useMapState from "hooks/map/useMapState";
import { I18nProvider } from "@lingui/react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { i18n } from "@lingui/core";
import { TourProvider } from "@reactour/tour";
import { FirebaseProvider } from "resources/contexts/firebaseContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider as ThemeProviderMui } from "@mui/material/styles";
import customMuiTheme from "styles/muiTheme";

const stripePromise = loadStripe(STRIPE_KEY);
const queryClient = new QueryClient();

export default function Providers({ children }) {
  const mapState = useMapState();

  return (
    <I18nProvider i18n={i18n}>
      <ReduxProvider store={store}>
        <TourProvider steps={[]}>
          <FirebaseProvider>
            <MapContext.Provider value={mapState}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StripeElementsProvider stripe={stripePromise}>
                  <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={bootstrap}>
                      <ThemeProviderMui theme={customMuiTheme}>
                        {children}
                      </ThemeProviderMui>
                    </ThemeProvider>
                  </QueryClientProvider>
                </StripeElementsProvider>
              </LocalizationProvider>
            </MapContext.Provider>
          </FirebaseProvider>
        </TourProvider>
      </ReduxProvider>
    </I18nProvider>
  );
}
