import { createSlice, createAction } from "@reduxjs/toolkit";
import purge from "store/purge";
import { emptyArr, emptyObj } from "utilities/empties";

const initialState = {
  firmwareList: [],
  isLoadingFirmwareList: false,

  firmwareProgressData: {},
  isLoadingFirmwareUpdateProgress: false,

  isLoadingFirmwareUpdateRequest: false,
  isLoadingFirmwareConfig: false,

  isLoadingRecoveryMode: false,

  isLoadingPollPosition: false,
};

export default createSlice({
  name: "DEVICE_DETAILS_DOMAIN",
  initialState,
  reducers: {
    firmwareUpdateRequestSuccess: (state) => {
      state.isLoadingFirmwareUpdateRequest = false;
    },
    firmwareListSuccess: (state, { payload }) => {
      state.isLoadingFirmwareList = false;
      state.firmwareList = payload ? payload : emptyArr;
    },
    firmwareUpdateProgressSucess: (state, { payload }) => {
      state.isLoadingFirmwareUpdateProgress = false;
      state.firmwareProgressData = payload ? payload : emptyObj;
    },
    firmwareConfigSucess: (state) => {
      state.isLoadingFirmwareConfig = false;
    },
    fetchingFirmwareUpdateProgress: (state) => {
      state.isLoadingFirmwareUpdateProgress = true;
    },
    fetchingFirmwareUpdateRequest: (state) => {
      state.isLoadingFirmwareUpdateRequest = true;
    },
    pollPositionSuccess: (state) => {
      state.isLoadingPollPosition = false;
    },
    recoveryModeSuccess: (state) => {
      state.isLoadingRecoveryMode = false;
    },
    fetchingFirmwareList: (state) => {
      state.isLoadingFirmwareList = true;
    },
    fetchingFirmwareConfig: (state) => {
      state.isLoadingFirmwareConfig = true;
    },
    requestPollPosition: (state) => {
      state.isLoadingPollPosition = true;
    },
    requestRecoveryMode: (state) => {
      state.isLoadingRecoveryMode = true;
    },
    error: (state) => {
      state.isLoadingRecoveryMode = false;
      state.isLoadingPollPosition = false;
      state.isLoadingFirmwareUpdateProgress = false;
      state.isLoadingFirmwareConfig = false;
      state.isLoadingFirmwareUpdateRequest = false;
      state.isLoadingFirmwareList = false;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const REQUEST_POLL_POSITION = createAction(
  "REQUEST_POLL_POSITION",
  (payload, meta) => ({
    payload,
    meta,
  })
);
export const REQUEST_RECOVERY_MODE = createAction(
  "REQUEST_RECOVERY_MODE",
  (payload, meta) => ({
    payload,
    meta,
  })
);
export const UPDATE_DEVICE_FIRMWARE_REQUEST = createAction(
  "UPDATE_DEVICE_FIRMWARE_REQUEST",
  (payload, meta) => ({
    payload,
    meta,
  })
);
export const GET_FIRMWARE_CONFIG = createAction(
  "GET_FIRMWARE_CONFIG",
  (payload, meta) => ({
    payload,
    meta,
  })
);
export const LIST_AVAILABLE_DEVICE_FIRMWARES = createAction(
  "LIST_AVAILABLE_DEVICE_FIRMWARES",
  (payload, meta) => ({
    payload,
    meta,
  })
);
export const GET_FIRMWARE_UPDATE_PROGRESS = createAction(
  "GET_FIRMWARE_UPDATE_PROGRESS",
  (payload, meta) => ({
    payload,
    meta,
  })
);
