import { channels, defaultChannel } from "./constants/activationCodeChannels";
export default function getChannelFromActivationCode(code) {
  const acceptedChannels = Object.keys(channels);
  const activationCode = code?.toUpperCase();

  let channelMatch = defaultChannel;

  for (let i = 0; i < acceptedChannels.length; i++) {
    const channel = acceptedChannels[i];

    if (activationCode?.search(channel) !== -1) {
      channelMatch = channels[channel];
      break;
    }
  }

  return channelMatch;
}
