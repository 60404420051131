export default {
  domainFetching: state => {
    state.isLoading = true;
  },
  domainSuccess: (state, { payload }) => {
    state.isLoading = false;
    state.ids = payload;
  },
  domainError: (state, { payload }) => {
    state.isLoading = false;
    state.error = payload;
  },
};
