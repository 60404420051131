const canadaCodes = {
  canada: "CA",
  ca: "CA",
};

const usCodes = {
  unitedstates: "US",
  usa: "US",
  us: "US",
};

/**
 * Retrieves the country code for Canada or the United States based on the provided input.
 * @param {string} input - The input representing either the full name or country code of Canada or the United States.
 * @returns {string|null} - The corresponding country code if a match is found, or CA if no match is found.
 */
export default function getCountryCode(input) {
  if (!input) return "CA"; // return canada by default, as all of our customers until now have been from canada

  const inputLowercase = input.toLowerCase().replace(/\s/g, "");

  if (canadaCodes.hasOwnProperty(inputLowercase)) {
    return canadaCodes[inputLowercase];
  }

  if (usCodes.hasOwnProperty(inputLowercase)) {
    return usCodes[inputLowercase];
  }

  for (const key in canadaCodes) {
    if (inputLowercase.includes(key)) {
      return canadaCodes[key];
    }
  }

  for (const key in usCodes) {
    if (inputLowercase.includes(key)) {
      return usCodes[key];
    }
  }

  return input;
}
