import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { persistor } from "configurations/redux";
import { PURGE, lOGOUT } from "store/rootReducer";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "configurations/firebase";

export default function useLogout() {
  const dispatch = useDispatch();

  const logout = useCallback(async () => {
    dispatch(lOGOUT());
    dispatch(PURGE());

    await signOut(firebaseAuth);
    await persistor.flush();
    await persistor.persist();
  }, [dispatch]);

  return logout;
}
