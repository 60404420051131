import { createSelector } from '@reduxjs/toolkit';

export const selectNotificationEvents = state =>
  state.entities.assets.domains.notificationEvents.data;

export const selectIsLoadingNotificationEvents = state =>
  state.entities.assets.domains.notificationEvents.isLoading;

export const selectNotificationEventsAsArray = createSelector(
  [selectNotificationEvents],
  notificationEvents => Object.values(notificationEvents)
);

export const buildSelectNotificationEventsByDid = did =>
  createSelector([selectNotificationEventsAsArray], notificationEvents =>
    notificationEvents.filter(event => event.did === did)
  );
