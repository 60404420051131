import jwtDecode from "jwt-decode";
import { store } from "configurations/redux";

export const getAccessToken = () => {
  const accessToken = store.getState().account.accessToken;

  return accessToken;
};

export const getDecodedToken = () => {
  const accessToken = store.getState().account.accessToken;
  return accessToken ? jwtDecode(accessToken) : "";
};

export const isAuthenticated = () => {
  return !!getAccessToken() && isTokenValid();
};

export const isTokenValid = () => {
  const expiresAt = store.getState().account.expiresAt;

  return new Date().getTime() <= expiresAt;
};

export const getSub = () => {
  let { sub } = getDecodedToken();
  return sub;
};

export const getRole = () => {
  let { "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": role } =
    getDecodedToken();
  return role;
};

export const getCompanyId = () => {
  let { cid } = getDecodedToken();
  return parseInt(cid);
};

export const getUserId = () => {
  let { uid } = getDecodedToken();
  return parseInt(uid);
};
