import { initializeApp, getApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { FIREBASE_CONFIG } from "./appConfig";

function getFirebaseApp(config) {
  try {
    return getApp();
  } catch {
    return initializeApp(config);
  }
}

const firebaseApp = getFirebaseApp(FIREBASE_CONFIG);

// Auth exports
export const firebaseAuth = getAuth(firebaseApp);
export const googleAuthProvider = new GoogleAuthProvider();

// Firestore exports
export const firestore = getFirestore(firebaseApp);

// Storage exports
export const storage = getStorage(firebaseApp);

// helpers
export const updateUserData = async (path, data) => {
  const ref = doc(firestore, path);
  try {
    await setDoc(ref, data, { merge: true });
  } catch (e) {
    throw new Error("Could not update firebase document");
  }

  return true;
};
