import { createSlice, createAction } from '@reduxjs/toolkit';
import purge from 'store/purge';

const initialState = {
  stripeCheckoutSession: undefined,

  isLoading: false,
};

export default createSlice({
  name: 'STRIPE_CHECKOUT',
  initialState,
  reducers: {
    fetching: (state) => {
      state.isLoading = true;
    },

    error: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    success: (state) => {
      state.isLoading = false;
    },

    setStripeCheckoutSession: (state, { payload }) => {
      state.stripeCheckoutSession = payload;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const CREATE_STRIPE_CHECKOUT_SESSION = createAction(
  'CREATE_STRIPE_CHECKOUT_SESSION',
  (payload, meta) => ({
    payload,
    meta,
  })
);
