import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  message: "",
  severity: "success",
  allowClose: true,
  autoHideDuration: 5000,
};

const snackbarSlice = createSlice({
  name: "SNACKBAR_UI",
  initialState,
  reducers: {
    showSnackbar: (state, action) => ({
      autoHideDuration: 5000,
      allowClose: true,
      ...action.payload,
      open: true,
    }),
    hideSnackbar: (state, action) => ({ ...state, open: false }),
  },
  extraReducers: {},
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
