import { takeLatest, takeEvery, fork } from 'redux-saga/effects';
import groupsSlice, { FETCH_GROUPS, ADD_GROUP, UPDATE_GROUP, DELETE_GROUP } from './slice';
import schema from './schema';
import { selectGroups } from './selectors';
import entityRequest from 'store/entities/xEntityRequest';

const actions = groupsSlice.actions;

function* watchFetch() {
  yield takeLatest(FETCH_GROUPS.type, entityRequest, {
    cacheSelector: selectGroups,
    schema,
    actions,
  });
}

function* watchAdd() {
  yield takeLatest(ADD_GROUP.type, entityRequest, { actions });
}

function* watchUpdate() {
  yield takeEvery(UPDATE_GROUP.type, entityRequest, { actions });
}

function* watchDelete() {
  yield takeLatest(DELETE_GROUP.type, entityRequest, { actions });
}

export default function* watchGroups() {
  yield fork(watchFetch);
  yield fork(watchDelete);
  yield fork(watchUpdate);
  yield fork(watchAdd);
}
