import { takeLatest, put } from "redux-saga/effects";
import slice, {
  UPDATE_STRIPE_CUSTOMER_PAYMENT_METHOD,
} from "store/stripe/customer/slice";
import {
  updateStripeCustomer,
  updateStripeSubscriptionPaymentMethod,
} from "resources/services/stripeService";

function* update({ payload }) {
  const { customerId, updatedCustomer, subscriptionId, paymentMethodId } =
    payload;
  const { setStripeCustomer, fetching, error, success } = slice.actions;

  yield put(fetching());

  try {
    const stripeCustomer = yield updateStripeCustomer(
      customerId,
      updatedCustomer
    );

    yield put(setStripeCustomer(stripeCustomer));
    yield updateStripeSubscriptionPaymentMethod(
      subscriptionId,
      paymentMethodId
    );

    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchUpdateCustomerPaymentMethod() {
  yield takeLatest(UPDATE_STRIPE_CUSTOMER_PAYMENT_METHOD.type, update);
}
