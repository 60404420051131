import { takeLatest, fork } from 'redux-saga/effects';
import companiesSlice, {
  FETCH_COMPANIES,
  ADD_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY,
} from './slice';
import schema from './schema';
import { selectCompanies } from './selectors';
import requestSaga from 'store/entities/xEntityRequest';

const actions = companiesSlice.actions;

export function* watchFetch() {
  yield takeLatest(FETCH_COMPANIES.type, requestSaga, {
    cacheSelector: selectCompanies,
    schema,
    actions,
  });
}

export function* watchAdd() {
  yield takeLatest(ADD_COMPANY.type, requestSaga, { actions });
}

export function* watchDelete() {
  yield takeLatest(DELETE_COMPANY.type, requestSaga, { actions });
}

export function* watchUpdate() {
  yield takeLatest(UPDATE_COMPANY.type, requestSaga, { actions });
}

export default function* companiesSagas() {
  yield fork(watchFetch);
  yield fork(watchAdd);
  yield fork(watchUpdate);
  yield fork(watchDelete);
}
