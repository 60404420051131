import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultEntityState, defaultEntityReducers } from 'store/defaults';
import purge from 'store/purge';
import { emptyObj } from 'utilities/empties';

export default createSlice({
  name: 'GEOPLACE_ASSETS_DOMAIN',
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,
    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload ? payload.entities.assets || emptyObj : state.data;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_GEOPLACE_ASSETS = createAction('FETCH_GEOPLACE_ASSETS');
export const ADD_GEOPLACE_ASSETS = createAction('ADD_GEOPLACE_ASSETS');
export const UPDATE_GEOPLACE_ASSETS = createAction('UPDATE_GEOPLACE_ASSETS');
