import { takeLatest, put, select } from "redux-saga/effects";
import slice, {
  DELETE_STRIPE_PAYMENT_METHOD,
} from "store/stripe/paymentMethods/slice";
import {
  detachStripePaymentMethod,
  getAllStripePaymentMethods,
} from "resources/services/stripeService";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { selectStripeCustomer } from "store/stripe/customer/selectors";
import { showSnackbar } from "store/ui/snackbar/slice";

function* fetch({ payload }) {
  const stripeCustomer = yield select(selectStripeCustomer);
  const { setStripePaymentMethods, fetching, error, success } = slice.actions;

  yield put(fetching());
  try {
    yield detachStripePaymentMethod(payload);
    const { data } = yield getAllStripePaymentMethods(stripeCustomer.id);

    yield put(setStripePaymentMethods(data));
    yield put(success());

    yield put(
      showSnackbar({
        message: i18n._(t`Payment method has been removed`),
        severity: "success",
      })
    );
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchDetachPaymentMethods() {
  yield takeLatest(DELETE_STRIPE_PAYMENT_METHOD.type, fetch);
}
