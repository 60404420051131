import { takeLatest, put } from "redux-saga/effects";
import slice, {
  REQUEST_RECOVERY_MODE,
} from "store/entities/assets/domains/deviceDetails/slice";
import getClientError from "utilities/getClientError";
import {
  startRecoveryMode,
  stopRecoveryMode,
} from "resources/services/commandService";

function* fetch({ payload, meta }) {
  const { uid, aid, intervalInSeconds, durationInMinutes } = payload;
  const { recoveryModeSuccess, requestRecoveryMode, error } = slice.actions;

  yield put(requestRecoveryMode());

  try {
    let response;
    if (intervalInSeconds && durationInMinutes) {
      response = yield startRecoveryMode({
        aid,
        uid,
        intervalInSeconds,
        durationInMinutes,
      });
    } else {
      response = yield stopRecoveryMode({
        aid,
        uid,
      });
    }

    if (response?.error) throw new Error(response.error);

    yield put(recoveryModeSuccess());
    meta?.onSuccess && meta.onSuccess(response);
  } catch (e) {
    const message = getClientError(e);

    yield put(error(message));
    meta?.onError && meta.onError(message);
  }
}

export default function* watchRequestRecoveryMode() {
  yield takeLatest(REQUEST_RECOVERY_MODE.type, fetch);
}
