import { createSlice, createAction } from "@reduxjs/toolkit";
import { defaultEntityReducers, defaultEntityState } from "store/defaults";
import purge from "store/purge";

export default createSlice({
  name: "ASSETS_REPORT_DOMAIN",
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,
    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload ? payload : state.data;
    },
    reset: () => defaultEntityState,
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_ASSETS_REPORT = createAction(
  "FETCH_ASSETS_REPORT",
  (payload, meta) => ({
    payload,
    meta,
  })
);
