import { takeLatest, fork } from 'redux-saga/effects';
import schedulesSlice, {
  FETCH_SCHEDULED_REPORTS,
  UPDATE_SCHEDULED_REPORT,
  DELETE_SCHEDULED_REPORT,
  ADD_SCHEDULED_REPORT,
} from './slice';
import schema from './schema';
import { selectScheduledReports } from './selectors';
import entityRequest from 'store/entities/xEntityRequest';

const actions = schedulesSlice.actions;

function* watchFetch() {
  yield takeLatest(FETCH_SCHEDULED_REPORTS.type, entityRequest, {
    cacheSelector: selectScheduledReports,
    schema,
    actions,
  });
}

function* watchUpdate() {
  yield takeLatest(UPDATE_SCHEDULED_REPORT.type, entityRequest, {
    actions: { ...actions, success: actions.updateSuccess },
  });
}

function* watchDelete() {
  yield takeLatest(DELETE_SCHEDULED_REPORT.type, entityRequest, { actions });
}

function* watchAdd() {
  yield takeLatest(ADD_SCHEDULED_REPORT.type, entityRequest, { actions });
}

export default function* scheduleReportSaga() {
  yield fork(watchFetch);
  yield fork(watchDelete);
  yield fork(watchAdd);
  yield fork(watchUpdate);
}
