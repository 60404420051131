import { schema } from 'normalizr';
import scheduleSchema from 'store/entities/schedules/schema';

export default new schema.Entity(
  'geoplaces',
  {
    schedule: scheduleSchema,
  },
  {
    idAttribute: 'fid',
  }
);
