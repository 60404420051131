import { takeLatest, fork } from 'redux-saga/effects';
import drivingScoreSlice, { FETCH_DRIVING_SCORES } from './slice';
import schema from './schema';
import entityRequest from 'store/entities/xEntityRequest';

function* watchFetch() {
  yield takeLatest(FETCH_DRIVING_SCORES.type, entityRequest, {
    actions: drivingScoreSlice.actions,
    schema,
  });
}

export default function* notificationEventsSaga() {
  yield fork(watchFetch);
}
