import React, { useCallback, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import {
  StyledModalBody,
  StyledModalFooter,
  StyledModal,
  StyledModalTitle,
  StyledButton,
  StyledModalHeader,
  StyledSubmitButton,
} from "./styles";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useHistory } from "react-router";

const defaultSubmitButtonText = i18n._(t`Submit`);

export default function ModalWrapper({
  children,
  title = "",
  altFooter,
  showFooter = true,
  submit,
  back,
  showBackButton = false,
  submitButtonText = defaultSubmitButtonText,
  hasBackButton,
  isLoading,
  disabled = false,
  isShowingCloseButton = true,
  onClose,
  size = "md",
  backdrop,
}) {
  const history = useHistory();
  const [show, setShow] = useState(true);
  const closeModalFn = useCallback(() => {
    setShow(false);
    onClose && onClose();
  }, [onClose]);

  return (
    <StyledModal
      centered
      size={size}
      show={show}
      onHide={closeModalFn}
      backdrop={backdrop}
    >
      <StyledModalHeader closeButton={isShowingCloseButton}>
        <StyledModalTitle>{title}</StyledModalTitle>
      </StyledModalHeader>

      <StyledModalBody>{children}</StyledModalBody>
      {showFooter && (
        <StyledModalFooter>
          {altFooter ? (
            altFooter
          ) : (
            <>
              {isShowingCloseButton && (
                <StyledButton
                  onClick={
                    hasBackButton ? () => history.goBack() : closeModalFn
                  }
                >
                  {hasBackButton ? i18n._(t`Back`) : i18n._(t`Cancel`)}
                </StyledButton>
              )}

              {showBackButton && back && (
                <StyledButton onClick={back}>{i18n._(t`Back`)}</StyledButton>
              )}

              {submit && (
                <StyledSubmitButton
                  disabled={disabled || isLoading}
                  onClick={submit}
                >
                  {isLoading ? (
                    <Spinner animation="grow" size="sm" />
                  ) : (
                    submitButtonText
                  )}
                </StyledSubmitButton>
              )}
            </>
          )}
        </StyledModalFooter>
      )}
    </StyledModal>
  );
}
