import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultEntityReducers, defaultEntityState } from 'store/defaults';
import purge from 'store/purge';

export default createSlice({
  name: 'SCHEDULES_ENTITY',
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;

      if (payload) {
        state.data[payload.target] = payload.schedules;
      }
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_SCHEDULES = createAction('FETCH_SCHEDULES', (payload, meta) => ({
  payload,
  meta,
}));
export const ADD_SCHEDULE = createAction('ADD_SCHEDULE');
export const DELETE_SCHEDULE = createAction('DELETE_SCHEDULE');
export const UPDATE_SCHEDULE = createAction('UPDATE_SCHEDULE');
