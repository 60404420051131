import { put, cancelled } from 'redux-saga/effects';
import setLoadingFromCache from './setLoadingFromCache';
import handleSuccess from './handleSuccess';
import normalizeResponse from './normalizeResponse';
import { CancelToken } from 'axios';
import handleError from './handleError'

export default function* entityRequest(
  { actions, cacheSelector, schema, responder },
  initiatingAction
) {
  const { type, payload: { reqFn, onSuccess, onError }, meta } = initiatingAction
  const { domainError, error } = actions;
  const errorAction = domainError || error;
  const source = CancelToken.source();

  try {
    yield setLoadingFromCache(cacheSelector, actions, type);

    const response = yield reqFn();

    // NOTE: some errors from server dont caught, so manually throw error if found in the body
    if(response && response.error) throw new Error(response.error)
    
    const normalized = normalizeResponse(response, schema);

    yield handleSuccess(response, normalized, onSuccess, actions, meta, type);
    yield (responder && responder({ response, normalized, meta }));
  } catch (e) {
    yield handleError(e, errorAction, onError)
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}
