import { createSlice, createAction } from '@reduxjs/toolkit';
import purge from 'store/purge';

const initialState = {
  stripePrices: undefined,

  isLoading: false,
};

export default createSlice({
  name: 'STRIPE_PRICES',
  initialState,
  reducers: {
    fetching: (state) => {
      state.isLoading = true;
    },

    error: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    success: (state) => {
      state.isLoading = false;
    },

    setStripePrices: (state, { payload }) => {
      state.stripePrices = payload;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const FETCH_STRIPE_PRICES = createAction('FETCH_STRIPE_PRICES');
