import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

/**
 * Validate if the given value is a valid phone number for either US or CA
 * @param {string} value - The phone number to be checked
 * @param {boolean} [required=false] - If the field is required or not
 * @returns {string|boolean} - Returns true if valid, or an error message otherwise
 */
export default function checkIsPhoneNumberValid(value, required = false) {
  // If it's not a required field and value is empty, return true
  if (!required && !value) return true;

  // Define a stricter pattern that allows for common phone number formatting with stricter area code validation
  // Pattern explanation:
  // - Optional country code: '1' followed by a separator (space, dot, or dash)
  // - Area code: Enclosed in optional parentheses, must start with a digit [2-9] and followed by any two digits
  // - Separator: Space, dot, or dash (optional but included for readability)
  // - First three digits: Must start with a digit [2-9], followed by any two digits
  // - Separator: Space, dot, or dash (optional but included for readability)
  // - Last four digits: Any four digits
  const phoneRegex =
    /^1?[ .-]?(\([2-9]\d{2}\)|[2-9]\d{2})[ .-]?[2-9]\d{2}[ .-]?\d{4}$/;

  // Check if the phone number matches the stricter pattern
  const isValid = phoneRegex.test(value);

  if (isValid) {
    return true;
  } else {
    return i18n._(
      t`Invalid phone number. Please enter a valid US or CA number.`
    );
  }
}
