import styled from 'styled-components/macro';
import { Container } from 'react-bootstrap';
import { bootstrap } from 'styles/theme';

export const ComponentContainer = styled(Container)`
  padding: ${bootstrap.spacer_three};
  background: white;
  box-shadow: ${bootstrap.shadow};

  border-radius: ${bootstrap.border_radius};
`;
