import { takeLatest } from 'redux-saga/effects';
import usersSlice from '../../slice';
import userAssetsSlice, { FETCH_USER_ASSETS } from './slice';
import schema from 'store/entities/assets/schema';
import { selectUserAssetIds } from './selectors';
import requestSaga from 'store/entities/xEntityRequest';

export default function* watchFetch() {
  yield takeLatest(FETCH_USER_ASSETS.type, requestSaga, {
    cacheSelector: selectUserAssetIds,
    schema,
    // NOTE: remove fetching to prevent listeners of asset loading state from being triggered
    actions: {
      ...usersSlice.actions,
      ...userAssetsSlice.actions,
      success: usersSlice.actions.successAssets,
      fetching: undefined,
    },
  });
}
