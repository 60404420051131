import React, { createContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth, firestore } from "configurations/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import useAssetsRealtime from "hooks/assets/useAssetsRealtime";

const FirebaseContext = createContext();

const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [auth, setAuth] = useState(null);
  const [systemInfo, setSystemInfo] = useState(null);

  useAssetsRealtime();

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(firebaseAuth, (authUser) => {
      setAuth(authUser ? authUser : null);
    });

    const unsubscribeSystemInfo = onSnapshot(
      doc(firestore, "system", "info"),
      (doc) => {
        if (doc.exists()) {
          setSystemInfo(doc.data());
        } else {
          setSystemInfo(null);
        }
      }
    );

    return () => {
      unsubscribeAuth();
      unsubscribeSystemInfo();
    };
  }, []);

  return (
    <FirebaseContext.Provider value={{ user, auth, systemInfo }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseContext, FirebaseProvider };
