import { takeLatest, fork } from 'redux-saga/effects';
import positionsSlice, { FETCH_POSITIONS } from './slice';
import schema from './schema';
import requestSaga from 'store/entities/xEntityRequest';

function* watchFetch() {
  yield takeLatest(FETCH_POSITIONS.type, requestSaga, {
    schema,
    actions: positionsSlice.actions,
  });
}

export default function* positionsSaga() {
  yield fork(watchFetch);
}
