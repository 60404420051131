import { takeLatest, fork } from "redux-saga/effects";
import wifiLocationsSlice, {
  REQUEST_BEACON_SCAN,
  FETCH_BEACON_SCAN_RESULTS,
  SAVE_BEACON_LOCATIONS,
} from "./slice";
import schema from "./schema";
import entityRequest from "store/entities/xEntityRequest";

const requestActions = {
  ...wifiLocationsSlice.actions,
  success: wifiLocationsSlice.actions.scanResultSuccess,
};

function* watchRequestBeaconScan() {
  yield takeLatest(REQUEST_BEACON_SCAN.type, entityRequest, {
    actions: wifiLocationsSlice.actions,
  });
}

function* watchSaveBeaconLocations() {
  yield takeLatest(SAVE_BEACON_LOCATIONS.type, entityRequest, {
    actions: wifiLocationsSlice.actions,
  });
}

function* watchFetchBeaconScanResults() {
  yield takeLatest(FETCH_BEACON_SCAN_RESULTS.type, entityRequest, {
    actions: requestActions,
    schema,
  });
}

export default function* wifiLocationsSaga() {
  yield fork(watchRequestBeaconScan);
  yield fork(watchFetchBeaconScanResults);
  yield fork(watchSaveBeaconLocations);
}
