import { takeLatest, fork } from 'redux-saga/effects';
import favouritesSlice, {
  FETCH_FAVOURITES,
  ADD_FAVOURITE,
  UPDATE_FAVOURITE,
  DELETE_FAVOURITE,
} from './slice';
import { selectFavourites } from './selectors';
import entityRequest from 'store/entities/xEntityRequest';
import schema from './schema';

const actions = favouritesSlice.actions;

function* watchFetch() {
  yield takeLatest(FETCH_FAVOURITES.type, entityRequest, {
    cacheSelector: selectFavourites,
    schema,
    actions,
  });
}

function* watchAdd() {
  yield takeLatest(ADD_FAVOURITE.type, entityRequest, { actions });
}

function* watchUpdate() {
  yield takeLatest(UPDATE_FAVOURITE.type, entityRequest, { actions });
}

function* watchDelete() {
  yield takeLatest(DELETE_FAVOURITE.type, entityRequest, { actions });
}

export default function* scheduleReportSaga() {
  yield fork(watchFetch);
  yield fork(watchAdd);
  yield fork(watchDelete);
  yield fork(watchUpdate);
}
