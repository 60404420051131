import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultEntityReducers, defaultEntityState } from 'store/defaults';
import purge from 'store/purge';

export default createSlice({
  name: 'FAVOURITES_ENTITY',
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload ? payload.entities.favourites : state.data;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_FAVOURITES = createAction('FETCH_FAVOURITES');
export const ADD_FAVOURITE = createAction('ADD_FAVOURITE');
export const DELETE_FAVOURITE = createAction('DELETE_FAVOURITE');
export const UPDATE_FAVOURITE = createAction('UPDATE_FAVOURITE');
