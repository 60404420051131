import { createSlice } from "@reduxjs/toolkit";
import purge from "store/purge";

const defaultState = {
  sortOptions: {
    sortKey: "nickname",
    sortType: "string",
    isAscending: false,
  },
};

export default createSlice({
  name: "ACCOUNT_UI",
  initialState: defaultState,
  reducers: {
    setSortOptions: (state, { payload }) => {
      state.sortOptions = { ...state.sortOptions, ...payload };
    },

    toggleIsAscending: (state, { payload }) => {
      state.sortOptions.isAscending =
        typeof payload === "boolean" ? payload : !state.sortOptions.isAscending;
    },

    reset: () => defaultState,
  },
  extraReducers: {
    ...purge(defaultState),
  },
});
