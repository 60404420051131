import { takeLatest, put } from "redux-saga/effects";
import slice, {
  FETCH_STRIPE_PAYMENT_HISTORY,
} from "store/stripe/paymentHistory/slice";
import { getStripePaymentHistory } from "resources/services/stripeService";

function* fetch({ payload }) {
  const { setStripePaymentHistory, fetching, error, success } = slice.actions;

  yield put(fetching());
  try {
    const { data } = yield getStripePaymentHistory(payload);

    yield put(setStripePaymentHistory(data));
    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchFetchPaymentHistory() {
  yield takeLatest(FETCH_STRIPE_PAYMENT_HISTORY.type, fetch);
}
