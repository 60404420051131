import { takeLatest, fork } from "redux-saga/effects";
import geoplaceAssetsSlice, {
  FETCH_GEOPLACE_ASSETS,
  UPDATE_GEOPLACE_ASSETS,
  ADD_GEOPLACE_ASSETS,
} from "./slice";
import schema from "./schema";
import entityRequest from "store/entities/xEntityRequest";

const actions = geoplaceAssetsSlice.actions;

function* watchFetch() {
  yield takeLatest(FETCH_GEOPLACE_ASSETS.type, entityRequest, {
    actions,
    schema,
  });
}

function* watchUpdate() {
  yield takeLatest(UPDATE_GEOPLACE_ASSETS.type, entityRequest, {
    actions,
  });
}

function* watchAdd() {
  yield takeLatest(ADD_GEOPLACE_ASSETS.type, entityRequest, { actions });
}

export default function* geoplaceAssetsSaga() {
  yield fork(watchFetch);
  yield fork(watchUpdate);
  yield fork(watchAdd);
}
