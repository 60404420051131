import { combineReducers } from '@reduxjs/toolkit';
import tripsSlice from './slice';
import tripPositionsSlice from './domains/positions/slice';

const domainReducers = combineReducers({
  positions: tripPositionsSlice.reducer,
});

export default combineReducers({
  entity: tripsSlice.reducer,
  domains: domainReducers,
});
