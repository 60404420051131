export enum UserRoles {
  Unknown = 0,
  ASP = 1,
  VAP = 2,
  Administrator = 10,
  EndUser = 11,
  GroupAdministrator = 15,
  //GroupEndUser = 16,
  TemporaryUser = 25,
  Invalid = 100,
}

export default UserRoles;
