import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultEntityReducers, defaultEntityState } from 'store/defaults';
import purge from 'store/purge';

export default createSlice({
  name: 'GROUPS_ENTITY',
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;

      if (!payload) return;
      if (!payload.entities.groups) return;

      payload.entities.groups[0] = ungrouped;

      state.data = payload ? payload.entities.groups : state.data;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_GROUPS = createAction('FETCH_GROUPS');
export const DELETE_GROUP = createAction('DELETE_GROUP');
export const ADD_GROUP = createAction('ADD_GROUP');
export const UPDATE_GROUP = createAction('UPDATE_GROUP');

const ungrouped = {
  gid: 0,
  groupName: 'Ungrouped',
  groupDescription: 'No group',
  parentGid: null,
};
