import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { RESET_PASSWORD } from "store/account/slice";
import {
  selectAccountError,
  selectIsLoadingAccount,
} from "store/account/selectors";
import accountSlice from "store/account/slice";
import { useForm, Controller } from "react-hook-form";
import { checkIsEmail } from "forms/validators";
import { showSnackbar } from "store/ui/snackbar/slice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";

export default function ResetPassword({ open, setOpen }) {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const error = useSelector(selectAccountError);
  const isLoading = useSelector(selectIsLoadingAccount);
  const { clearError } = accountSlice.actions;

  const onSubmit = useCallback(
    (data) => {
      dispatch(
        RESET_PASSWORD(
          { username: data.username },
          {
            onSuccess: (email) => {
              setOpen(false);
              dispatch(
                showSnackbar({
                  message: i18n._(
                    t`A password reset email has been sent to ${email}.`
                  ),
                })
              );
            },
            onError: () => {
              dispatch(
                showSnackbar({
                  message: i18n._(
                    t`An error occurred while trying to reset your password. Please contact support or try again later.`
                  ),
                  severity: "error",
                })
              );
            },
          }
        )
      );
    },
    [dispatch, setOpen]
  );

  useEffect(() => {
    dispatch(clearError());
  }, [clearError, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialogContent-root": {
          padding: 3,
        },
        "& .MuiDialogActions-root": {
          padding: 2,
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {i18n._(t`Reset Password`)}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <LockResetIcon color="primary" sx={{ fontSize: 48 }} />
          </Box>
          <Typography variant="body1" align="center" paragraph>
            {i18n._(t`Enter your email address to reset your password.`)}
          </Typography>
          <Controller
            name="username"
            control={control}
            rules={{
              required: i18n._(t`This field is required.`),
              validate: checkIsEmail,
            }}
            render={({ onChange, value }) => (
              <TextField
                value={value}
                onChange={(e) => onChange(e.target.value)}
                variant="outlined"
                margin="normal"
                fullWidth
                label={i18n._(t`Email`)}
                error={!!errors.username}
                helperText={errors.username?.message}
                disabled={isLoading}
              />
            )}
          />
          {error && (
            <Typography color="error" variant="body2" paragraph>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {i18n._(t`Cancel`)}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} /> : null}
          >
            {isLoading ? i18n._(t`Submitting...`) : i18n._(t`Reset Password`)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
