import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";

export default [
  { value: 0, label: i18n._(t`Greenwich Mean Time (UTC)`) },
  { value: -600, label: i18n._(t`Hawaii Time`) },
  { value: -540, label: i18n._(t`Alaska Time`) },
  { value: -480, label: i18n._(t`Pacific Time`) },
  { value: -420, label: i18n._(t`Mountain Time`) },
  { value: -360, label: i18n._(t`Central Time`) },
  { value: -300, label: i18n._(t`Eastern Time`) },
  { value: -240, label: i18n._(t`Atlantic Time`) },
  { value: -210, label: i18n._(t`Newfoundland Time`) },
];
