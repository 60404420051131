import { fork } from "redux-saga/effects";
import { combineReducers } from "redux";
import customerSlice from "./customer/slice";
import paymentMethodSlice from "./paymentMethods/slice";
import paymentHistorySlice from "./paymentHistory/slice";
import checkoutSlice from "./checkout/slice";
import subscriptionSlice from "./subscription/slice";
import subscriptionItemsSlice from "./subscriptionItems/slice";
import productsSlice from "./products/slice";
import pricesSlice from "./prices/slice";
import cartSlice from "./cart/slice";
import taxRatesSlice from "./taxRates/slice";
import invoicesSlice from "./invoices/slice";
import watchFetchPaymentHistory from "./paymentHistory/sagas/fetchPaymentHistory";
import watchFetchPaymentMethods from "./paymentMethods/sagas/fetchPaymentMethods";
import watchDetachPaymentMethod from "./paymentMethods/sagas/detachPaymentMethod";
import watchCreatePaymentMethod from "./paymentMethods/sagas/createPaymentMethod";
import watchCreateSubscription from "./subscription/sagas/createSubscription";
import watchUpdateSubscription from "./subscription/sagas/updateSubscription";
import watchCancelSubscription from "./subscription/sagas/cancelSubscription";
import watchFetchSubscriptionItems from "./subscriptionItems/sagas/fetchSubscriptionsItems";
import watchCreateSubscriptionItem from "./subscriptionItems/sagas/createSubscriptionItem";
import watchUpdateSubscriptionItem from "./subscriptionItems/sagas/updateSubscriptionItem";
import watchDeleteSubscriptionItem from "./subscriptionItems/sagas/deleteSubscriptionItem";
import watchFetchCustomer from "./customer/sagas/fetchCustomer";
import watchUpdateCustomer from "./customer/sagas/updateCustomer";
import watchFetchStripeProducts from "./products/sagas/fetchStripeProducts";
import watchFetchStripePrices from "./prices/sagas/fetchStripePrices";
import watchCreateCheckoutSession from "./checkout/sagas/createCheckoutSession";
import watchFetchStripeTaxRates from "./taxRates/sagas/fetchTaxRates";
import watchRemoveCartItem from "./cart/sagas/removeCartItem";
import watchFetchCustomerAsAdmin from "./customer/sagas/fetchCustomerAsAdmin";
import watchUpdateCustomerPaymentMethod from "./customer/sagas/updateCustomerPaymentMethod";
import watchInvoices from "./invoices/sagas/fetchLatestInvoice";

export default combineReducers({
  customer: customerSlice.reducer,
  paymentMethods: paymentMethodSlice.reducer,
  paymentHistory: paymentHistorySlice.reducer,
  checkout: checkoutSlice.reducer,
  subscription: subscriptionSlice.reducer,
  subscriptionItems: subscriptionItemsSlice.reducer,
  products: productsSlice.reducer,
  prices: pricesSlice.reducer,
  cart: cartSlice.reducer,
  taxRates: taxRatesSlice.reducer,
  invoices: invoicesSlice.reducer,
});

export function* watchStripe() {
  //PAYMENT HISTORY
  yield fork(watchFetchPaymentHistory);

  // PAYMENT METHODS
  yield fork(watchFetchPaymentMethods);
  yield fork(watchDetachPaymentMethod);
  yield fork(watchCreatePaymentMethod);

  // CUSTOMERS
  yield fork(watchFetchCustomer);
  yield fork(watchFetchCustomerAsAdmin);
  yield fork(watchUpdateCustomer);
  yield fork(watchUpdateCustomerPaymentMethod);

  // SUBSCRIPTION
  // yield fork(watchFetchSubscription);
  yield fork(watchCreateSubscription);
  yield fork(watchUpdateSubscription);
  yield fork(watchCancelSubscription);

  // SUBSCRIPTION ITEMS
  yield fork(watchFetchSubscriptionItems);
  yield fork(watchUpdateSubscriptionItem);
  yield fork(watchCreateSubscriptionItem);
  yield fork(watchDeleteSubscriptionItem);

  // PRODUCTS
  yield fork(watchFetchStripeProducts);

  // PRICES
  yield fork(watchFetchStripePrices);
  yield fork(watchFetchStripeTaxRates);

  // CHECKOUT
  yield fork(watchCreateCheckoutSession);

  // CART
  yield fork(watchRemoveCartItem);

  // INVOICES
  yield fork(watchInvoices);
}
