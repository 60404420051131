import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultEntityReducers, defaultEntityState } from 'store/defaults';
import purge from 'store/purge';

export default createSlice({
  name: 'COMPANIES_ENTITY',
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload ? payload.entities.companies : state.data;
    },

    addCompanies: (state, { payload }) => {
      state.data = { ...state.data, ...payload.entities.companies };
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_COMPANIES = createAction('FETCH_COMPANIES');
export const ADD_COMPANY = createAction('ADD_COMPANY');
export const DELETE_COMPANY = createAction('DELETE_COMPANY');
export const UPDATE_COMPANY = createAction('UPDATE_COMPANY');
