import { createSlice } from '@reduxjs/toolkit';
import purge from 'store/purge';

const defaultState = {
  current: undefined,
};

export default createSlice({
  name: 'FAVOURITE_UI',
  initialState: defaultState,
  reducers: {
    setFavourite: (state, { payload }) => {
      state.current = payload;
    },

    reset: () => defaultState,
  },
  extraReducers: {
    ...purge(defaultState),
  },
});
