import { normalize } from 'normalizr';

export default function normalizeResponse(response, schema) {
  if (!schema || !response) return;

  const data = Array.isArray(response) ? response : [response];
  const normalized = normalize(data, [schema]);

  return normalized;
}
