import { createSlice, createAction } from '@reduxjs/toolkit';
import purge from 'store/purge';

const initialState = {
  taxRates: [],

  isLoading: false,
};

export default createSlice({
  name: 'STRIPE_TAX_RATES',
  initialState,
  reducers: {
    fetching: (state) => {
      state.isLoading = true;
    },

    error: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    success: (state) => {
      state.isLoading = false;
    },

    setTaxRates: (state, { payload }) => {
      state.taxRates = payload;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const FETCH_STRIPE_TAX_RATES = createAction('FETCH_STRIPE_TAX_RATES');
export const UPDATE_STRIPE_TAX_RATE = createAction('UPDATE_STRIPE_TAX_RATE', (payload, meta) => ({
  payload,
  meta,
}));
export const DELETE_STRIPE_TAX_RATE = createAction('DELETE_STRIPE_TAX_RATE');
export const CREATE_STRIPE_TAX_RATE = createAction('CREATE_STRIPE_TAX_RATE');
