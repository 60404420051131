import { takeLatest, put, delay } from "redux-saga/effects";
import slice, {
  CREATE_STRIPE_SUBSCRIPTION,
} from "store/stripe/subscription/slice";
import { createStripeSubscription } from "resources/services/stripeService";
import { FETCH_STRIPE_CUSTOMER } from "store/stripe/customer/slice";
import getClientError from "utilities/getClientError";
import accountSlice from "store/account/slice";
import { getMyUser } from "resources/services/userService";

function* fetch({ payload, meta }) {
  const { fetching, error, success } = slice.actions;
  const { setMyUser } = accountSlice.actions;

  yield put(fetching());
  try {
    const res = yield createStripeSubscription(payload);

    if (res.error) {
      throw new Error(res.error);
    }

    // NOTE: give some delay so the webhook can action the new devices and remove any user flags
    yield delay(2000);
    yield put(FETCH_STRIPE_CUSTOMER());

    const myUser = yield getMyUser();

    yield put(setMyUser(myUser));
    yield put(success());

    meta?.onSuccess && meta.onSuccess(res);
  } catch (e) {
    const errorMessage = getClientError(e);
    yield put(error(errorMessage));
    meta?.onError && meta.onError(errorMessage);
  }
}

export default function* watchCreateSubscription() {
  yield takeLatest(CREATE_STRIPE_SUBSCRIPTION.type, fetch);
}
