import React, { useState, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router";
import useAuthorization from "hooks/authorization/useAuthorization";
import client from "configurations/fetch";
import { showSnackbar } from "store/ui/snackbar/slice";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import useQuery from "hooks/utilities/useQuery";
import {
  selectIsLoadingAccount,
  selectMyCompany,
  selectMyUser,
} from "store/account/selectors";
import { LOGIN } from "store/account/slice";
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
  Paper,
  MenuItem,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { logo } from "styles/theme";
import { SUPPORT_EMAIL } from "configurations/appConfig";

export default function Contact() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isLoading = useSelector(selectIsLoadingAccount);
  const { isAuthenticated } = useAuthorization();
  const user = useSelector(selectMyUser);
  const company = useSelector(selectMyCompany);
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  const hasAccessToken = useMemo(() => query.get("access_token"), [query]);
  const { handleSubmit, errors, control } = useForm();

  const supportMutation = useMutation({
    mutationFn: (data) => client.post("/support/submit", data),
    onSuccess: () => {
      setIsSubmitted(true);
    },
    onError: () => {
      dispatch(
        showSnackbar({
          message: i18n._(
            t`Something went wrong, plesase email us at ${SUPPORT_EMAIL}`
          ),
          severity: "error",
        })
      );
    },
  });

  const attemptAutoLogin = React.useCallback(() => {
    if (isAuthenticated) {
      return;
    }

    const access_token = query.get("access_token");
    const expires_in = query.get("expires_in");

    if (access_token && expires_in) {
      dispatch(
        LOGIN(
          {
            access_token,
            expires_in,
          },
          {
            onError: (errorMessage) => {
              dispatch(
                showSnackbar({
                  message: errorMessage,
                  severity: "error",
                })
              );
            },
          }
        )
      );
    }
  }, [dispatch, isAuthenticated, query]);

  useEffect(() => {
    attemptAutoLogin();
  }, []);

  const onSubmit = (data) => {
    const formData = {
      ...data,
      email: user.email,
      name: user.name,
      phone: user.phone || company.phone,
    };
    supportMutation.mutate(formData);
  };

  const handleBack = () => {
    history.goBack();
  };

  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!isAuthenticated) {
    return (
      <Container maxWidth="sm">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Paper
            elevation={3}
            sx={{ p: 4, textAlign: "center", borderRadius: "8px" }}
          >
            <Typography variant="h4" gutterBottom>
              Authentication Required
            </Typography>
            <Typography variant="body1">
              Please log in to access the contact page.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/login")}
              startIcon={<ArrowBackIcon />}
              sx={{ mt: 4 }}
            >
              Login
            </Button>
          </Paper>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
          sx={{ alignSelf: "flex-start", mb: 2 }}
        >
          Back
        </Button>
        <Paper elevation={3} sx={{ p: 4, width: "100%", borderRadius: "8px" }}>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ width: 280, marginBottom: 24 }}
            />
          </Box>
          {isSubmitted ? (
            <Box textAlign="center">
              <Stack spacing={4}>
                <Typography variant="h4" gutterBottom>
                  Thank You!
                </Typography>
                <Typography variant="body1" paragraph>
                  We have received your message and will get back to you as soon
                  as possible.
                </Typography>
              </Stack>
            </Box>
          ) : (
            <>
              <Typography
                variant="h4"
                gutterBottom
                align="center"
                sx={{ mb: 3 }}
              >
                Contact Us
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="reason"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ value, onChange }) => (
                    <TextField
                      select
                      value={value}
                      onChange={onChange}
                      label="Reason for contact"
                      fullWidth
                      margin="normal"
                      error={!!errors.reason}
                      helperText={errors.reason?.message}
                      sx={{ mb: 2 }}
                    >
                      <MenuItem value="general">General Inquiry</MenuItem>
                      <MenuItem value="support">Technical Support</MenuItem>
                      <MenuItem value="feedback">Feedback</MenuItem>
                      {hasAccessToken && (
                        <MenuItem value="delete-account">
                          Delete Account Request
                        </MenuItem>
                      )}
                      <MenuItem value="other">Other</MenuItem>
                    </TextField>
                  )}
                />
                <Controller
                  name="message"
                  rules={{ required: true }}
                  control={control}
                  defaultValue=""
                  render={({ value, onChange }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      label="Message"
                      fullWidth
                      margin="normal"
                      multiline
                      rows={4}
                      error={!!errors.message}
                      helperText={errors.message?.message}
                      sx={{ mb: 3 }}
                    />
                  )}
                />
                <Box display="flex" justifyContent="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={supportMutation.isLoading}
                    sx={{
                      minWidth: 200,
                      height: 48,
                    }}
                  >
                    {supportMutation.isLoading ? "Sending..." : "Send Message"}
                  </Button>
                </Box>
              </form>
            </>
          )}
        </Paper>
      </Box>
    </Container>
  );
}
