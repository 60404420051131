import { takeLatest, fork } from "redux-saga/effects";
import wifiLocationsSlice, {
  REQUEST_WIFI_SCAN,
  FETCH_WIFI_SCAN_RESULTS,
  SAVE_WIFI_LOCATIONS,
} from "./slice";
import schema from "./schema";
import entityRequest from "store/entities/xEntityRequest";

const requestActions = {
  ...wifiLocationsSlice.actions,
  success: wifiLocationsSlice.actions.scanResultSuccess,
};

function* watchRequestWifiScan() {
  yield takeLatest(REQUEST_WIFI_SCAN.type, entityRequest, {
    actions: wifiLocationsSlice.actions,
  });
}

function* watchSaveWifiLocations() {
  yield takeLatest(SAVE_WIFI_LOCATIONS.type, entityRequest, {
    actions: wifiLocationsSlice.actions,
  });
}

function* watchFetchWifiScanResults() {
  yield takeLatest(FETCH_WIFI_SCAN_RESULTS.type, entityRequest, {
    actions: requestActions,
    schema,
  });
}

export default function* wifiLocationsSaga() {
  yield fork(watchRequestWifiScan);
  yield fork(watchFetchWifiScanResults);
  yield fork(watchSaveWifiLocations);
}
