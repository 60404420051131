import { createSlice, createAction } from "@reduxjs/toolkit";
import purge from "store/purge";

const initialState = {
  cart: {},
  roamingAssetIds: [],

  isDirty: false,
  isLoading: false,
};

export default createSlice({
  name: "STRIPE_CART",
  initialState,
  reducers: {
    error: (state, { payload }) => {
      state.error = payload;
    },

    setCart: (state, { payload }) => {
      state.cart = payload;
      state.isDirty = false;
    },

    setRoamingAssetIds: (state, { payload }) => {
      state.roamingAssetIds = payload;
    },

    addRoamingAsset: (state, { payload }) => {
      state.roamingAssetIds = updateRoamingAssets(
        payload,
        state.roamingAssetIds,
        "add"
      );
    },

    removeRoamingAsset: (state, { payload }) => {
      state.roamingAssetIds = updateRoamingAssets(
        payload,
        state.roamingAssetIds,
        "delete"
      );
    },

    addItemToCart: (state, { payload }) => {
      // NOTE: remove before adding to handle changing the plan of an existing device subscription
      const removed = remove(state.cart, payload);

      removed[payload.price] = removed[payload.price]
        ? [...removed[payload.price], payload.aid]
        : [payload.aid];

      state.cart = removed;

      // check if the 'shouldDirty' prop is sent in the payload, override with it
      if (payload.hasOwnProperty("shouldDirty")) {
        state.isDirty = !!payload.shouldDirty;
      } else {
        state.isDirty = true;
      }
    },

    removeItemFromCart: (state, { payload }) => {
      const removed = remove(state.cart, payload);

      state.cart = removed;
      state.isDirty = true;
    },

    resetCart: (state) => {
      state.cart = {};
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

function updateRoamingAssets(aid, roamingAssetIds, type) {
  const aids = new Set(roamingAssetIds);
  if (type === "delete") aids.delete(aid);
  if (type === "add") aids.add(aid);
  return [...aids];
}

// NOTE: returns copy of cart object with item removed
function remove(cart, payload) {
  const asArray = Object.entries(cart);

  const copy = asArray.reduce((acc, cur) => {
    const [price, cart] = cur;

    const cartCopy = [...(cart || [])];
    const index = cartCopy.findIndex((aid) => aid === payload.aid);

    index > -1 && cartCopy.splice(index, 1);

    acc[price] = cartCopy;

    return acc;
  }, {});

  return copy;
}

export const REMOVE_CART_ITEM = createAction("REMOVE_CART_ITEM");
