import { fork } from "redux-saga/effects";
import { combineReducers } from "redux";
import watchFetchAssetsStatus from "./status/saga";
import watchNotificationEvents from "./notificationEvents/sagas";
import watchPositions from "./positions/sagas";
import watchReport from "./report/saga";
import watchDrivingScores from "./drivingScores/sagas";
import watchMaintenanceAlerts from "./maintenanceAlerts/sagas";
import watchFuelMaintenanceLogs from "./fuelLogs/sagas";
import watchMaintenanceLogs from "./maintenanceLogs/sagas";
import watchWifiLocations from "./wifiLocations/sagas";
import watchBeaconLocations from "./beaconLocations/sagas";
import watchDeviceDetails from "./deviceDetails/sagas";
import watchFetchAvailableSolutionPlans from "./solutionPlans/sagas";
import statusSlice from "./status/slice";
import reportSlice from "./report/slice";
import notificationEventsSlice from "./notificationEvents/slice";
import positionsSlice from "./positions/slice";
import drivingScores from "./drivingScores/slice";
import maintenanceAlerts from "./maintenanceAlerts/slice";
import fuelMaintenanceLogs from "./fuelLogs/slice";
import maintenanceLogs from "./maintenanceLogs/slice";
import wifiLocations from "./wifiLocations/slice";
import beaconLocations from "./beaconLocations/slice";
import deviceDetails from "./deviceDetails/slice";
import solutionPlans from "./solutionPlans/slice";

export function* watchDomains() {
  yield fork(watchFetchAssetsStatus);
  yield fork(watchNotificationEvents);
  yield fork(watchPositions);
  yield fork(watchReport);
  yield fork(watchDrivingScores);
  yield fork(watchMaintenanceAlerts);
  yield fork(watchFuelMaintenanceLogs);
  yield fork(watchMaintenanceLogs);
  yield fork(watchWifiLocations);
  yield fork(watchBeaconLocations);
  yield fork(watchDeviceDetails);
  yield fork(watchFetchAvailableSolutionPlans);
}

export const domainReducers = combineReducers({
  status: statusSlice.reducer,
  report: reportSlice.reducer,
  notificationEvents: notificationEventsSlice.reducer,
  maintenanceAlerts: maintenanceAlerts.reducer,
  positions: positionsSlice.reducer,
  drivingScores: drivingScores.reducer,
  fuelMaintenanceLogs: fuelMaintenanceLogs.reducer,
  maintenanceLogs: maintenanceLogs.reducer,
  wifiLocations: wifiLocations.reducer,
  beaconLocations: beaconLocations.reducer,
  deviceDetails: deviceDetails.reducer,
  solutionPlans: solutionPlans.reducer,
});
