import { useState, useCallback } from "react";

export default function useMapState() {
  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const [mapCache, setMapCache] = useState({});
  const [bounds, setBounds] = useState();

  const [tripPolyline, setTripPolyline] = useState(null);
  const [polygonShapeComplete, setPolygonShapeComplete] = useState(false);

  const clearMapContext = useCallback(() => {
    setMap(null);
    setMaps(null);
    setMapCache({});

    setTripPolyline(null);
    setPolygonShapeComplete(false);
    // drawingManager && drawingManager.setMap(null);
  }, []);

  return {
    map,
    setMap,
    maps,
    setMaps,
    mapCache,
    setMapCache,
    clearMapContext,
    tripPolyline,
    setTripPolyline,
    polygonShapeComplete,
    setPolygonShapeComplete,
  };
}
