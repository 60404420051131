import { takeLatest, put } from 'redux-saga/effects';
import slice, { FETCH_STRIPE_TAX_RATES } from 'store/stripe/taxRates/slice';
import { getStripeTaxRates } from 'resources/services/stripeService';

function* fetch({ payload }) {
  const { setTaxRates, fetching, error, success } = slice.actions;

  yield put(fetching());
  try {
    const { data } = yield getStripeTaxRates(payload);

    yield put(setTaxRates(data));
    yield put(success());
  } catch (e) {
    console.log(e);
    yield put(error(e));
  }
}

export default function* watchFetchStripeTaxRates() {
  yield takeLatest(FETCH_STRIPE_TAX_RATES.type, fetch);
}
