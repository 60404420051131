import { takeLatest, fork } from "redux-saga/effects";
import devicesSlice, { FETCH_DEVICES } from "./slice";
import schema from "./schema";
import { selectDevices } from "./selectors";
import requestSaga from "store/entities/xEntityRequest";

export function* watchFetch() {
  yield takeLatest(FETCH_DEVICES.type, requestSaga, {
    cacheSelector: selectDevices,
    schema,
    actions: devicesSlice.actions,
  });
}

export default function* devicesSagas() {
  yield fork(watchFetch);
}
