import { createSlice, createAction } from "@reduxjs/toolkit";
import { defaultEntityReducers, defaultEntityState } from "store/defaults";
import purge from "store/purge";

export default createSlice({
  name: "SOLUTION_PLANS_DOMAIN",
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,
    success: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_AVAILABLE_SOLUTION_PLANS = createAction(
  "FETCH_AVAILABLE_SOLUTION_PLANS",
  (payload, meta) => ({
    payload,
    meta,
  })
);
