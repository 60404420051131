import { takeLatest, put, select } from "redux-saga/effects";
import slice, { FETCH_STRIPE_CUSTOMER } from "store/stripe/customer/slice";
import cartSlice from "store/stripe/cart/slice";
import { selectMyUser } from "store/account/selectors";
import { createStripeCustomer } from "resources/services/stripeService";

function* fetch() {
  const { setStripeCustomer, fetching, error, success } = slice.actions;
  const { setCart, setRoamingAssetIds } = cartSlice.actions;
  const { firstName, lastName, email } = yield select(selectMyUser);

  yield put(fetching());

  try {
    const stripeCustomer = yield createStripeCustomer({
      email,
      name: `${firstName} ${lastName}`,
    });

    const cart = {};
    let roamingAssetIds = [];

    const subscription = stripeCustomer?.subscriptions?.data[0];

    if (subscription) {
      roamingAssetIds =
        subscription?.metadata?.roamingAssetIds?.split(",") || [];
      // NOTE: build the cart based on the customers current subscription items
      subscription.items.data.forEach((item) => {
        const priceId = item.price.id;
        const assetIds = item.metadata?.assetIds;

        if (assetIds) cart[priceId] = assetIds.split(",");
      });
    }

    yield put(setCart(cart));
    yield put(setRoamingAssetIds(roamingAssetIds));

    yield put(setStripeCustomer(stripeCustomer));
    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchFetchCustomer() {
  yield takeLatest(FETCH_STRIPE_CUSTOMER.type, fetch);
}
