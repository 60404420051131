import { takeLatest, put } from "redux-saga/effects";
import slice, {
  GET_FIRMWARE_UPDATE_PROGRESS,
} from "store/entities/assets/domains/deviceDetails/slice";
import { getDeviceFirmwareProgress } from "resources/services/deviceService";
import getClientError from "utilities/getClientError";

function* fetch({ payload, meta }) {
  const { did } = payload;
  const {
    firmwareUpdateProgressSucess,
    fetchingFirmwareUpdateProgress,
    error,
  } = slice.actions;

  yield put(fetchingFirmwareUpdateProgress());

  try {
    const response = yield getDeviceFirmwareProgress(did);

    if (response?.error) throw new Error(response.error);

    yield put(firmwareUpdateProgressSucess(response));
    meta?.onSuccess && meta.onSuccess(response);
  } catch (e) {
    const message = getClientError(e);

    yield put(error(message));
    meta?.onError && meta.onError(message);
  }
}

export default function* watchFetchFirmwareUpdateProgress() {
  yield takeLatest(GET_FIRMWARE_UPDATE_PROGRESS.type, fetch);
}
