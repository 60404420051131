import { createSlice, createAction } from "@reduxjs/toolkit";
import { defaultEntityReducers, defaultEntityState } from "store/defaults";
import purge from "store/purge";
import { emptyObj } from "utilities/empties";

export default createSlice({
  name: "MAINTENANCE_LOGS_DOMAIN",
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload
        ? payload.entities.maintenanceLogs || emptyObj
        : emptyObj;
    },

    updateSuccess: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_MAINTENANCE_LOGS = createAction("FETCH_MAINTENANCE_LOGS");
export const DELETE_MAINTENANCE_LOG = createAction("DELETE_MAINTENANCE_LOG");
export const ADD_MAINTENANCE_LOG = createAction("ADD_MAINTENANCE_LOG");
export const UPDATE_MAINTENANCE_LOG = createAction("UPDATE_MAINTENANCE_LOG");
