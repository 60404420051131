import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { selectSnackbar } from "store/ui/snackbar/selectors";
import { hideSnackbar } from "store/ui/snackbar/slice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MaterialSnackbar() {
  const dispatch = useDispatch();
  const { open, message, severity, autoHideDuration, allowClose } =
    useSelector(selectSnackbar);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideSnackbar());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={allowClose ? handleClose : null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={allowClose ? handleClose : null} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
