import React from "react";
import LoginForm from "forms/Login/Login";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import loginBackground from "assets/images/login-bg.png";

export default function LoginContainer() {
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          flex: isMediumUp ? "0 0 40%" : "1 1 100%",
          backgroundImage: `url(${loginBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: isMediumUp ? "relative" : "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          flex: isMediumUp ? "0 0 60%" : "1 1 100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          zIndex: 1,
          position: "relative",
        }}
      >
        <LoginForm />
      </Box>
    </Box>
  );
}
