import { put } from "redux-saga/effects";
import getClientError from "utilities/getClientError";

export default function* handleError(e, errorAction, onError) {
  const responseMessage = getClientError(e);

  const errorMessage = responseMessage
    ? responseMessage
    : `Error With Request, Please Try Again Later`;

  onError && onError(errorMessage);
  yield errorAction && put(errorAction(errorMessage));
}
