import { createSlice, createAction } from "@reduxjs/toolkit";
import { defaultEntityReducers, defaultEntityState } from "store/defaults";
import purge from "store/purge";
import { emptyObj } from "utilities/empties";

const initialState = {
  isLoadingAllCompaniesUsers: false,

  allCompaniesUsers: undefined,
};

export default createSlice({
  name: "USERS_ENTITY",
  initialState: { ...defaultEntityState, ...initialState },
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload ? payload.entities.users : state.data;
    },

    successCompaniesUsers: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload ? { ...state.data, ...payload } : state.data;
    },

    addUsers: (state, { payload }) => {
      state.data = { ...state.data, ...payload.entities.users };
    },

    successAssets: (state, { payload }) => {
      const assets = payload.entities.assets || emptyObj;

      const merged = Object.keys(state.data).reduce((acc, cur) => {
        acc[cur] = {
          ...state.data[cur],
          aidList: Object.values(assets).map((asset) => asset.aid),
        };
        return acc;
      }, {});
      state.data = merged;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_USERS = createAction("FETCH_USERS");
export const UPDATE_USER = createAction("UPDATE_USER");
export const ADD_USER = createAction("ADD_USER");
export const DELETE_USER = createAction("DELETE_USER");

export const FETCH_USERS_BY_COMPANY = createAction("FETCH_USERS_BY_COMPANY");
