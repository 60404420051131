import { takeLatest, put, select } from "redux-saga/effects";
import slice, {
  CREATE_STRIPE_PAYMENT_METHOD,
} from "store/stripe/paymentMethods/slice";
import { attachStripePaymentMethod } from "resources/services/stripeService";
import { UPDATE_STRIPE_CUSTOMER } from "store/stripe/customer/slice";
import accountSlice from "store/account/slice";
import { getMyUser } from "resources/services/userService";
import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { CardElement } from "@stripe/react-stripe-js";
import { selectStripeCustomer } from "store/stripe/customer/selectors";
import { selectAssetsActivationChannels } from "store/entities/assets/selectors";
import { showSnackbar } from "store/ui/snackbar/slice";

function* create({ payload, meta }) {
  const { values, stripe, elements } = payload;
  const {
    city,
    country,
    addressLine1,
    addressLine2,
    shippingAddress,
    state,
    name,
    companyName,
    setAsDefault = false,
    shippingSameAsBilling = false,
  } = values;

  const { addStripePaymentMethod, fetching, error, success } = slice.actions;
  const { setMyUser } = accountSlice.actions;

  yield put(fetching());

  const address = {
    city,
    line1: addressLine1,
    line2: addressLine2,
    country: typeof country === "object" ? country?.value : country,
    state: typeof state === "object" ? state?.value : state,
  };

  let updatedCustomer = {
    address,
    description: companyName || "",
    shipping: {
      address,
      name,
    },
    metadata: {
      // channels,
    },
  };

  try {
    const { paymentMethod } = yield stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        address,
        name,
      },
    });

    const stripeCustomer = yield select(selectStripeCustomer);
    // const channels = yield select(selectAssetsActivationChannels);

    const hasDefault = stripeCustomer?.invoice_settings?.default_payment_method;

    const attachedPaymentMethod = yield attachStripePaymentMethod(
      paymentMethod.id,
      {
        customer: stripeCustomer.id,
      }
    );

    if (attachedPaymentMethod?.error) {
      throw new Error(attachedPaymentMethod.error);
    }

    yield put(addStripePaymentMethod(attachedPaymentMethod));

    // if the user doesn't have a default method already or they flagged it to set as default
    if (!hasDefault || setAsDefault) {
      updatedCustomer["invoice_settings"] = {
        default_payment_method: paymentMethod.id,
      };
    }

    // if the shipping is not the same as the billing, then just set the shipping address
    if (!shippingSameAsBilling) {
      updatedCustomer["shipping"]["address"] = { line1: shippingAddress };
    }

    // update the user with the new payment method
    yield put(
      UPDATE_STRIPE_CUSTOMER({
        id: stripeCustomer.id,
        updatedCustomer,
      })
    );

    // update the user with new data from the server, to remove any potential redirect 'flags'
    const user = yield getMyUser();
    yield put(setMyUser(user));

    meta?.onSuccess && meta.onSuccess(attachedPaymentMethod);
    yield put(success());
  } catch (e) {
    meta?.onError && meta.onError(e.message);
    yield put(error(e));
  }
}

export default function* watchCreatePaymentMethods() {
  yield takeLatest(CREATE_STRIPE_PAYMENT_METHOD.type, create);
}
