import { put } from "redux-saga/effects";
import reportSlice from "../slice";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { showSnackbar } from "store/ui/snackbar/slice";

export default function* successResponder({ response, meta }) {
  const { success, error } = reportSlice.actions;

  try {
    const tables = Object.values(response);

    if (tables[0].length === 0) {
      yield put(
        showSnackbar({
          message: i18n._(t`No data is available for this report`),
          severity: "warning",
        })
      );
    }

    // NOTE: remove white space from object keys that server sends as some PDF formatting hack
    const reports = Object.entries(response).reduce((acc, [key, table]) => {
      acc[key] = Object.values(table).map((row) => {
        return Object.entries(row).reduce((acc, [key, value]) => {
          acc[key.trim()] = value;
          return acc;
        }, {});
      });

      return acc;
    }, {});

    yield put(success(reports));
    meta?.onSuccess && meta.onSuccess();
  } catch (e) {
    yield put(
      showSnackbar({
        message: e,
        severity: "error",
      })
    );
    yield put(error(e));
    meta?.onError && meta.onError();
  }
}
