import { createSlice, createAction } from "@reduxjs/toolkit";
import purge from "store/purge";

const initialState = {
  subscription: undefined,
  isLoading: false,
};

export default createSlice({
  name: "STRIPE_SUBSCRIPTION",
  initialState,
  reducers: {
    fetching: (state) => {
      state.isLoading = true;
    },

    error: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    success: (state) => {
      state.isLoading = false;
    },

    setStripeSubscription: (state, { payload }) => {
      state.subscription = payload;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

// export const FETCH_STRIPE_SUBSCRIPTION = createAction('FETCH_STRIPE_SUBSCRIPTION');
export const CREATE_STRIPE_SUBSCRIPTION = createAction(
  "CREATE_STRIPE_SUBSCRIPTION",
  (payload, meta) => ({
    payload,
    meta,
  })
);
export const CANCEL_STRIPE_SUBSCRIPTION = createAction(
  "CANCEL_STRIPE_SUBSCRIPTION",
  (payload, meta) => ({
    payload,
    meta,
  })
);
export const UPDATE_STRIPE_SUBSCRIPTION = createAction(
  "UPDATE_STRIPE_SUBSCRIPTION",
  (payload, meta) => ({
    payload,
    meta,
  })
);
