import sidebarUI from "./sidebar/slice";
import loginUI from "./login/slice";
import assetListUI from "./assetList/slice";
import assetMapSettingsUI from "./assetMapSettings/slice";
import mapUI from "./map/slice";
import tripsUI from "./trips/slice";
import geoplacesUI from "./geoplaces/slice";
import favouriteUI from "./favourite/slice";
import accountUI from "./account/slice";
import snackbarReducer from "./snackbar/slice";
import modalsUI from "./modals/slice";
import devicesUI from "./devices/slice";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageLocal from "redux-persist/lib/storage";

const assetListUIPersist = {
  key: "assetList",
  storage: storageLocal,
};

const assetMapSettingsUIPersist = {
  key: "assetSettings",
  storage: storageLocal,
  whitelist: ["isLiveMap", "isAutofit"],
};

const mapUIPersist = {
  key: "map",
  storage: storageLocal,
};

export default combineReducers({
  assetList: persistReducer(assetListUIPersist, assetListUI.reducer),
  assetMapSettings: persistReducer(
    assetMapSettingsUIPersist,
    assetMapSettingsUI.reducer
  ),
  devices: devicesUI.reducer,
  map: persistReducer(mapUIPersist, mapUI.reducer),
  favourite: favouriteUI.reducer,
  login: loginUI.reducer,
  sidebar: sidebarUI.reducer,
  trips: tripsUI.reducer,
  geoplaces: geoplacesUI.reducer,
  account: accountUI.reducer,
  snackbar: snackbarReducer,
  modals: modalsUI.reducer,
});
