import { takeLatest, put } from "redux-saga/effects";
import slice, {
  REQUEST_POLL_POSITION,
} from "store/entities/assets/domains/deviceDetails/slice";
import getClientError from "utilities/getClientError";
import { pollDevicePosition } from "resources/services/commandService";

function* fetch({ payload, meta }) {
  const { uid, aid } = payload;
  const { pollPositionSuccess, requestPollPosition, error } = slice.actions;

  yield put(requestPollPosition());

  try {
    const response = yield pollDevicePosition({ aid, uid });

    if (response?.error) throw new Error(response.error);

    yield put(pollPositionSuccess());
    meta?.onSuccess && meta.onSuccess(response);
  } catch (e) {
    const message = getClientError(e);

    yield put(error(message));
    meta?.onError && meta.onError(message);
  }
}

export default function* watchRequestPollPosition() {
  yield takeLatest(REQUEST_POLL_POSITION.type, fetch);
}
