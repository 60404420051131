import client from "configurations/fetch";

export const getToken = (username, password) => {
  var data = `username=${encodeURIComponent(
    username
  )}&password=${encodeURIComponent(password)}&grant_type=password`;

  return client.post(`/oauth2/token`, data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  });
};

export const getImpersonationToken = (uid, cid) => {
  return client.get(`/token/company/${cid}/user/${uid}`);
};

export const testAuthToken = (jwt) => {
  return client.get(`/tokentest/test?jwt=${jwt}`);
};
