export default function setValueToLocalStorage(key, value) {
  if (
    typeof value === "string" ||
    typeof value === "boolean" ||
    typeof value === "number"
  ) {
    localStorage.setItem(key, value.toString());
  } else {
    throw new Error("Unsupported value type for local storage.");
  }
}
