import React from "react";
import { TITLE } from "configurations/appConfig";
import "./styles2.css"; // Make sure to create this file with the CSS content provided above

export default function PulsingMapMarkerLoader() {
  return (
    <div className="loader-container">
      <div className="map-marker-wrapper">
        <div className="map-marker"></div>
        <div className="map-marker-pulse"></div>
      </div>
      <h1 className="title">{TITLE}</h1>
    </div>
  );
}
