import { createSlice } from "@reduxjs/toolkit";
import purge from "store/purge";

const defaultState = {
  hoveredAssetId: undefined,
  hoveredPositionId: undefined,
  hoveredMapIconId: undefined,

  hoveredIconOnMap: undefined,

  isShowingUnfocusedAssets: true,
  isShowingExpanded: true,

  inBoundsAssetIds: [],

  lastPositionQuery: "",
};

export default createSlice({
  name: "MAP_UI",
  initialState: defaultState,
  reducers: {
    setLastPositionQuery: (state, { payload }) => {
      state.lastPositionQuery = payload;
    },

    setHoveredAssetId: (state, { payload }) => {
      state.hoveredAssetId = payload;
    },

    setHoveredPositionId: (state, { payload }) => {
      state.hoveredPositionId = payload;
    },

    setHoveredMapIconId: (state, { payload }) => {
      state.hoveredMapIconId = payload;
    },

    setInBoundsAssetIds: (state, { payload }) => {
      state.inBoundsAssetIds = payload;
    },

    setIsShowingUnfocusedAssets: (state, { payload }) => {
      state.isShowingUnfocusedAssets = payload;
    },

    toggleIsShowingUnfocusedAssets: (state) => {
      state.isShowingUnfocusedAssets = !state.isShowingUnfocusedAssets;
    },

    toggleIsShowingExpanded: (state) => {
      state.isShowingExpanded = !state.isShowingExpanded;
    },
  },
  extraReducers: {
    ...purge(defaultState),
  },
});
