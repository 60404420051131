import { takeLatest } from "redux-saga/effects";
import reportSlice, { FETCH_ASSETS_REPORT } from "./slice";
import entityRequest from "store/entities/xEntityRequest";
import successResponder from "./responders/successResponder";

export default function* watchFetch() {
  yield takeLatest(FETCH_ASSETS_REPORT.type, entityRequest, {
    responder: successResponder,
    actions: reportSlice.actions,
  });
}
