import { takeLatest, put } from "redux-saga/effects";
import slice, {
  LIST_AVAILABLE_DEVICE_FIRMWARES,
} from "store/entities/assets/domains/deviceDetails/slice";
import { listDeviceFirmwares } from "resources/services/deviceService";
import getClientError from "utilities/getClientError";

function* fetch({ payload, meta }) {
  const { did } = payload;
  const { firmwareListSuccess, fetchingFirmwareList, error } = slice.actions;

  yield put(fetchingFirmwareList());

  try {
    const response = yield listDeviceFirmwares(did);

    if (response?.error) throw new Error(response.error);

    yield put(firmwareListSuccess(response));
    meta?.onSuccess && meta.onSuccess(response);
  } catch (e) {
    const message = getClientError(e);

    yield put(error(message));
    meta?.onError && meta.onError(message);
  }
}

export default function* watchFetchAvailableFirmwareList() {
  yield takeLatest(LIST_AVAILABLE_DEVICE_FIRMWARES.type, fetch);
}
