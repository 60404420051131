import { takeLatest, fork } from 'redux-saga/effects';
import scheduleGeoplacesSlice, {
  FETCH_SCHEDULE_GEOPLACES,
  UPDATE_SCHEDULE_GEOPLACE,
} from './slice';
import requestSaga from 'store/entities/xEntityRequest';
import schema from 'store/entities/geoplaces/schema';

function* watchFetch() {
  yield takeLatest(FETCH_SCHEDULE_GEOPLACES.type, requestSaga, {
    schema,
    actions: scheduleGeoplacesSlice.actions,
  });
}

function* watchUpdate() {
  yield takeLatest(UPDATE_SCHEDULE_GEOPLACE.type, requestSaga, {
    actions: scheduleGeoplacesSlice.actions,
    success: scheduleGeoplacesSlice.actions.updateSuccess,
  });
}

export default function* scheduleGeoplacesDomainSagas() {
  yield fork(watchFetch);
  yield fork(watchUpdate);
}
