import { createSelector } from 'reselect';
import { emptyObj } from 'utilities/empties';

export const selectReports = state => state.entities.reports.data;
export const selectReportsAsArray = createSelector([selectReports], reports =>
  Object.values(reports)
);
export const selectIsLoadingReports = state => state.entities.reports.isLoading;
export const selectReportsError = state => state.entities.reports.error;

export const selectAssetOnlyReports = createSelector([selectReportsAsArray], reports => {
  if (!reports) return;

  return reports.filter(
    report => !report.supportsDriverSearch && !report.supportsGeofenceSearch && report.spDateRange
  );
});

export const buildSelectReportById = rid =>
  createSelector([selectReports], reports => {
    return reports && rid ? reports[rid] : emptyObj;
  });
