import { takeLatest, put } from 'redux-saga/effects';
import slice, { FETCH_STRIPE_SUBSCRIPTION_ITEMS } from 'store/stripe/subscriptionItems/slice';
import { getStripeSubscriptionItems } from 'resources/services/stripeService';

function* fetch({ payload }) {
  const { setSubscriptionItems, fetching, error, success } = slice.actions;

  yield put(fetching());
  try {
    const { data } = yield getStripeSubscriptionItems(payload);

    yield put(setSubscriptionItems(data));
    yield put(success());
  } catch (e) {
    console.log(e);
    yield put(error(e));
  }
}

export default function* watchFetchSubscriptionItems() {
  yield takeLatest(FETCH_STRIPE_SUBSCRIPTION_ITEMS.type, fetch);
}
