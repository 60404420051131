import { createSlice, createAction } from "@reduxjs/toolkit";
import {
  defaultGranularLoadingReducers,
  defaultGranularLoadingState,
} from "store/defaults";
import purge from "store/purge";
import merge from "lodash/merge";

const initialState = {
  ...defaultGranularLoadingState,
};

export default createSlice({
  name: "ASSETS_ENTITY",
  initialState,
  reducers: {
    ...defaultGranularLoadingReducers,
    success: {
      reducer: (state, { payload, meta }) => {
        const incomingAssets = payload.entities.assets;

        Object.keys(incomingAssets).forEach((aid) => {
          if (state.data[aid]) {
            // Update all properties except deviceStatus
            Object.entries(incomingAssets[aid]).forEach(([key, value]) => {
              if (key !== "deviceStatus") {
                state.data[aid][key] = value;
              }
            });
          } else {
            // If it's a new asset, add it but initialize deviceStatus to null
            state.data[aid] = {
              ...incomingAssets[aid],
              deviceStatus: null, // Initial state for deviceStatus
            };
          }
        });

        state.isLoading = { ...state.isLoading, [meta.initiatorType]: false };
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    successAssetsStatus: (state, action) => {
      const updates = action.payload.entities.assets;
      Object.keys(updates).forEach((aid) => {
        if (state.data[aid]) {
          // Directly replace the deviceStatus with the new one
          state.data[aid].deviceStatus = updates[aid].deviceStatus;
        }
      });
    },
    realtimeAssetStatus: (state, action) => {
      const updates = action.payload.entities.assets;

      Object.keys(updates).forEach((aid) => {
        // Ignore updates for assets not existing in state
        if (state.data[aid] && state.data[aid].deviceStatus) {
          const currentLatestTs =
            state.data[aid].deviceStatus.latestPosition?.ts;
          const incomingLatestTs = updates[aid].deviceStatus.latestPosition?.ts;

          // Convert UTC date strings to timestamps for comparison
          const currentTs = currentLatestTs
            ? Date.parse(currentLatestTs)
            : null;
          const incomingTs = incomingLatestTs
            ? Date.parse(incomingLatestTs)
            : null;

          // Proceed with merge only if the incoming timestamp is more recent
          if (!currentTs || incomingTs > currentTs) {
            merge(state.data[aid].deviceStatus, updates[aid].deviceStatus);
          }
        }
      });
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const FETCH_ASSETS = createAction("FETCH_ASSETS", (payload, meta) => ({
  payload,
  meta,
}));

export const UPDATE_ASSET = createAction("UPDATE_ASSET", (payload, meta) => ({
  payload,
  meta,
}));

export const UPDATE_ASSET_VEHICLE_ODOMETER = createAction(
  "UPDATE_ASSET_VEHICLE_ODOMETER",
  (payload, meta) => ({
    payload,
    meta,
  })
);

export const UPDATE_ASSET_ENGINE_HOURS = createAction(
  "UPDATE_ASSET_ENGINE_HOURS",
  (payload, meta) => ({
    payload,
    meta,
  })
);

export const SHARE_ASSET = createAction("SHARE_ASSET", (payload, meta) => ({
  payload,
  meta,
}));
