import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultEntityReducers, defaultEntityState } from 'store/defaults';
import purge from 'store/purge';
import { emptyObj } from 'utilities/empties';

export default createSlice({
  name: 'DRIVING_SCORE_DOMAIN',
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload ? payload.entities.drivingScores || emptyObj : emptyObj;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_DRIVING_SCORES = createAction('FETCH_DRIVING_SCORES');
