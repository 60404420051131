import { takeLatest, takeEvery, fork } from "redux-saga/effects";
import notificationsEventSlice, {
  FETCH_NOTIFICATION_EVENTS,
  UPDATE_NOTIFICATION_EVENT,
  DELETE_NOTIFICATION_EVENT,
  ADD_NOTIFICATION_EVENT,
} from "./slice";
import schema from "./schema";
import entityRequest from "store/entities/xEntityRequest";
import { selectNotificationEvents } from "./selectors";

const actions = notificationsEventSlice.actions;

function* watchFetch() {
  yield takeLatest(FETCH_NOTIFICATION_EVENTS.type, entityRequest, {
    cacheSelector: selectNotificationEvents,
    actions,
    schema,
  });
}

function* watchUpdate() {
  yield takeEvery(UPDATE_NOTIFICATION_EVENT.type, entityRequest, {
    actions: { ...actions, success: actions.updateSuccess },
    cacheSelector: selectNotificationEvents,
  });
}

function* watchDelete() {
  yield takeLatest(DELETE_NOTIFICATION_EVENT.type, entityRequest, { actions });
}

function* watchAdd() {
  yield takeLatest(ADD_NOTIFICATION_EVENT.type, entityRequest, { actions });
}

export default function* notificationEventsSaga() {
  yield fork(watchFetch);
  yield fork(watchDelete);
  yield fork(watchAdd);
  yield fork(watchUpdate);
}
