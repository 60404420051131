import { createSelector } from 'reselect';
import { emptyObj } from 'utilities/empties';

export const selectFavourites = state => state.entities.favourites.data;
export const selectIsLoadingFavourites = state => state.entities.favourites.isLoading;

export const selectFavouritesAsArray = createSelector([selectFavourites], favourites =>
  favourites ? Object.values(favourites) : []
);

export const buildSelectFavouriteById = id =>
  createSelector([selectFavourites], favourites => {
    return id && favourites ? favourites[id] : emptyObj;
  });
