import { takeLatest, put } from "redux-saga/effects";
import slice, {
  UPDATE_DEVICE_FIRMWARE_REQUEST,
} from "store/entities/assets/domains/deviceDetails/slice";
import { updateDeviceFirmware } from "resources/services/commandService";
import getClientError from "utilities/getClientError";

function* update({ payload, meta }) {
  const { firmwareUpdateRequestSuccess, fetchingFirmwareUpdateRequest, error } =
    slice.actions;

  yield put(fetchingFirmwareUpdateRequest());

  try {
    const response = yield updateDeviceFirmware(payload);

    if (response?.error) throw new Error(response.error);

    yield put(firmwareUpdateRequestSuccess(response));
    meta?.onSuccess && meta.onSuccess(response);
  } catch (e) {
    console.log(e);
    const message = getClientError(e);

    yield put(error(message));
    meta?.onError && meta.onError(message);
  }
}

export default function* watchUpdateDeviceFirmware() {
  yield takeLatest(UPDATE_DEVICE_FIRMWARE_REQUEST.type, update);
}
