import { isAuthenticated, getAccessToken } from "utilities/authorization";
import { API_URL, ORIGIN } from "./appConfig";
import merge from "lodash/merge";

const getOptions = (options) => {
  const defaultOptions = {
    headers: {
      "Accept-Language": localStorage.getItem("language"),
      "Content-Type": "application/json",
      "X-HostName": ORIGIN,
    },
  };

  // Only add Authorization if authenticated
  if (isAuthenticated()) {
    defaultOptions.headers.Authorization = `Bearer ${getAccessToken()}`;
  }

  return merge(defaultOptions, options);
};

const parseResponse = async (response) => {
  const contentType = response.headers.get("content-type") || "";

  // Return early for no-content responses
  if (response.status === 204) {
    return null;
  }

  // Handle successful responses
  if (response.ok) {
    if (contentType.includes("application/json")) {
      return response.json();
    }
    return response.text();
  }

  // Handle error responses
  const errorText = await response.text();
  const error = new Error(errorText.replace(/^["']|["']$/g, ""));
  error.status = response.status;
  error.statusText = response.statusText;
  throw error;
};

const parseData = (data, options) => {
  if (options.headers?.["Content-Type"]) return data;
  return JSON.stringify(data);
};

const api = {
  request:
    (method) =>
    async (url, data = null, options = {}) => {
      const fetchOptions = getOptions({
        ...options,
        method,
        ...(data && { body: parseData(data, options) }),
      });

      const response = await fetch(`${API_URL}${url}`, fetchOptions);
      return parseResponse(response);
    },
};

export default {
  get: api.request("GET"),
  post: api.request("POST"),
  put: api.request("PUT"),
  delete: api.request("DELETE"),
};
