import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultEntityReducers, defaultEntityState } from 'store/defaults';
import purge from 'store/purge';

export default createSlice({
  name: 'REPORTS_ENTITY',
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;

      // NOTE: fix for API's incorrect hidden column casing
      if (!payload) return;
      if (!payload.entities.reports) return;

      Object.entries(payload.entities.reports).reduce((acc, [key, val]) => {
        if (!val.hiddenColumns) return acc;

        val.hiddenColumns = val.hiddenColumns
          .split('|')
          .map(colName => colName.charAt(0).toLowerCase() + colName.slice(1));

        acc[key] = val;
        return acc;
      }, {});

      state.data = payload ? payload.entities.reports : state.data;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_REPORTS = createAction('FETCH_REPORTS');
