import { takeLatest, put } from "redux-saga/effects";
import slice, {
  CREATE_STRIPE_SUBSCRIPTION_ITEM,
} from "store/stripe/subscriptionItems/slice";
import { createStripeSubscriptionItem } from "resources/services/stripeService";

function* create({ payload, meta }) {
  const { fetching, error, success } = slice.actions;

  yield put(fetching());
  try {
    const res = yield createStripeSubscriptionItem(payload);

    if (res.error) {
      throw new Error(res.error);
    }

    meta?.onSuccess && meta.onSuccess(res);

    yield put(success());
  } catch (e) {
    const error = e.message ? e.message : e;
    meta?.onError && meta.onError(error);
    yield put(error(error));
  }
}

export default function* watchCreateSubscriptionItems() {
  yield takeLatest(CREATE_STRIPE_SUBSCRIPTION_ITEM.type, create);
}
