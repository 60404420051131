import { createSlice, createAction } from "@reduxjs/toolkit";
import { defaultEntityReducers, defaultEntityState } from "store/defaults";
import purge from "store/purge";
import merge from "lodash/merge";

export default createSlice({
  name: "DEVICES_ENTITY",
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      const devices = removeInvalidComponentTypes(payload);

      state.isLoading = false;
      state.data = devices ? merge(state.data, devices) : state.data;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_DEVICES = createAction("FETCH_DEVICES");

function removeInvalidComponentTypes(payload) {
  const devices = payload.entities.devices;
  if (!devices) return;

  Object.entries(devices).reduce((acc, [key, value]) => {
    value.deviceUsagePlan.componentList =
      value.deviceUsagePlan.componentList.filter(
        (component) => component.componentType === 10
      );

    acc[key] = value;

    return acc;
  }, {});

  return devices;
}
