import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as PlusIcon } from "assets/icons/plus_icon.svg";
import { Button, ToggleButton } from "react-bootstrap";
import { bootstrap } from "styles/theme";

export function AddButton(props) {
  return (
    <Button variant="link" {...props}>
      <StyledPlusIcon />
    </Button>
  );
}

const StyledPlusIcon = styled(PlusIcon)`
  height: 30px;
  width: 30px;
  color: ${bootstrap.primary};
  rect {
    fill: ${bootstrap.primary};
  }
`;

export const StyledToggleButton = styled(ToggleButton)`
  background: #adb5bd !important;

  color: #9fa0a1;

  &.active {
    background: ${bootstrap.primary} !important;
    color: #fff !important;
  }

  &.disabled {
    background: #adb5bd !important;
    opacity: 0.6;
  }
`;
