import favouritesSlice from './favourites/slice';
import groupsSlice from './groups/slice';
import reportsSlice from './reports/slice';
import trips from './trips';
import assets from './assets';
import users from './users';
import schedules from './schedules';
import companiesSlice from './companies/slice';
import geoplaces from './geoplaces';
import deviceEvents from './deviceEvents/slice';
import devices from './devices/slice';
import { combineReducers } from 'redux';
import scheduledReportsSlice from './scheduledReports/slice';

export default combineReducers({
  favourites: favouritesSlice.reducer,
  groups: groupsSlice.reducer,
  reports: reportsSlice.reducer,
  scheduledReports: scheduledReportsSlice.reducer,
  companies: companiesSlice.reducer,
  deviceEvents: deviceEvents.reducer,
  devices: devices.reducer,
  users,
  schedules,
  geoplaces,
  assets,
  trips,
});
