import { takeLatest, takeEvery, fork, put } from "redux-saga/effects";
import tripsSlice, { FETCH_TRIPS, SHARE_TRIP, UPDATE_TRIP } from "./slice";
import tripsUISlice from "../../ui/trips/slice";
import schema from "./schema";
import entityRequest from "store/entities/xEntityRequest";
import watchDomains from "./domains";

function* watchFetch() {
  yield takeLatest(FETCH_TRIPS.type, entityRequest, {
    actions: tripsSlice.actions,
    schema,
  });
}

function* updateTrip({ payload: { reqFn, onSuccess } }) {
  const { error } = tripsSlice.actions;

  try {
    const response = yield reqFn();
    onSuccess && onSuccess();
  } catch (e) {
    yield put(error(e));
  }
}

function* watchUpdateTrip() {
  yield takeLatest(UPDATE_TRIP.type, updateTrip);
}

function* shareTrip({ payload: { reqFn, onSuccess } }) {
  const { setShareTripModal } = tripsUISlice.actions;

  yield put(setShareTripModal({ isLoading: true }));

  try {
    const response = yield reqFn();
    onSuccess && onSuccess();

    yield put(
      setShareTripModal({ isLoading: false, open: false, shareTripURL: null })
    );
  } catch (e) {
    yield put(setShareTripModal({ isLoading: false, error: e }));
  }
}

function* watchShareTrip() {
  yield takeLatest(SHARE_TRIP.type, shareTrip);
}

export default function* tripSaga() {
  yield fork(watchFetch);
  yield fork(watchUpdateTrip);
  yield fork(watchShareTrip);
  yield fork(watchDomains);
}
