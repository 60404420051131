const channels = {
  AMZ: "Amazon",
  CST: "Costco",
  DTC: "Shopify",
  LWS: "Loneworker",
  USA: "Amazon US",
  LONEWORKER: "Loneworker",
  SHOPIFY: "Shopify",
  AMAZON: "Amazon",
  COSTCO: "Costco",
};

const defaultChannel = "Internal";

export { channels, defaultChannel };
