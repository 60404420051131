import { createSlice, createAction } from '@reduxjs/toolkit';
import purge from 'store/purge';

const initialState = {
  data: {},
  error: undefined,
  isLoading: false,

  currentSchedulesDeviceEvents: [],
};

export default createSlice({
  name: 'DEVICE_EVENTS_ENTITY',
  initialState,
  reducers: {
    fetching: state => {
      state.isLoading = true;
    },

    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload ? payload.actionCodeOverride : state.data;
    },

    error: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    setCurrentSchedulesDeviceEvents: (state, { payload }) => {
      state.isLoading = false;
      state.currentSchedulesDeviceEvents = payload;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const FETCH_DEVICE_EVENTS = createAction('FETCH_DEVICE_EVENTS');
export const FETCH_SCHEDULES_DEVICE_EVENTS = createAction('FETCH_SCHEDULES_DEVICE_EVENTS');
