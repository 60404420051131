import { createSelector } from "@reduxjs/toolkit";
import {
  FETCH_ASSETS,
  UPDATE_ASSET,
  SHARE_ASSET,
  UPDATE_ASSET_VEHICLE_ODOMETER,
  UPDATE_ASSET_ENGINE_HOURS,
} from "./slice";
import { emptyObj } from "utilities/empties";
import getChannelFromActivationCode from "utilities/channels";

export const selectAssets = (state) => {
  return state.entities.assets.entity.data;
};
export const selectIsFetchingAssets = (state) =>
  state.entities.assets.entity.isLoading[FETCH_ASSETS.type];
export const selectIsUpdatingAsset = (state) =>
  state.entities.assets.entity.isLoading[UPDATE_ASSET.type];
export const selectIsSharingAsset = (state) =>
  state.entities.assets.entity.isLoading[SHARE_ASSET.type];

export const selectIsUpdatingOdometer = (state) =>
  state.entities.assets.entity.isLoading[UPDATE_ASSET_VEHICLE_ODOMETER.type];
export const selectIsUpdatingEngineHours = (state) =>
  state.entities.assets.entity.isLoading[UPDATE_ASSET_ENGINE_HOURS.type];

export const selectAssetsAsArray = createSelector([selectAssets], (assets) =>
  Object.values(assets)
);

export const selectAssetsWithDeviceStatus = createSelector(
  [selectAssetsAsArray],
  (assets) =>
    assets.filter(
      (asset) =>
        asset && asset.deviceStatus && asset.deviceStatus.latestPosition
    )
);
export const selectAssetIds = createSelector([selectAssets], (assets) =>
  Object.keys(assets)
);

export const selectAssetsActivationChannels = createSelector(
  [selectAssetsAsArray],
  (assets) => {
    const channels =
      assets &&
      assets.map((asset) =>
        getChannelFromActivationCode(asset.deviceActivationCode)
      );
    return channels ? [...new Set(channels)].toString() : [];
  }
);

export const buildSelectAssetById = (id) =>
  createSelector([selectAssets], (assets) =>
    assets && id ? assets[id] : emptyObj
  );
export const buildSelectAssetsByIds = (aidList) =>
  createSelector(
    [selectAssets],
    (assets) => aidList && aidList.map((aid) => assets[aid])
  );

export const buildSelectGroupsAssets = (gid) =>
  createSelector([selectAssetsAsArray], (assets) => {
    return assets.filter((asset) => asset.gid === gid);
  });
