import { takeLatest, fork } from 'redux-saga/effects';
import schedulesSlice, {
  FETCH_SCHEDULES,
  UPDATE_SCHEDULE,
  DELETE_SCHEDULE,
  ADD_SCHEDULE,
} from './slice';
import schema from './schema';
import requestSaga from 'store/entities/xEntityRequest';
import successResponder from './responders/successResponder';
import { watchDomains } from './domains';

const actions = schedulesSlice.actions;

function* watchFetch() {
  yield takeLatest(FETCH_SCHEDULES.type, requestSaga, {
    // NOTE: do not use cache selector because schedules needs to refresh data by target change
    // cacheSelector: selectSchedules,
    responder: successResponder,
    schema,
    actions: { ...schedulesSlice.actions, success: undefined },
  });
}

function* watchUpdate() {
  yield takeLatest(UPDATE_SCHEDULE.type, requestSaga, { actions });
}

function* watchDelete() {
  yield takeLatest(DELETE_SCHEDULE.type, requestSaga, { actions });
}

function* watchAdd() {
  yield takeLatest(ADD_SCHEDULE.type, requestSaga, { actions });
}

export default function* scheduleReportSaga() {
  yield fork(watchFetch);
  yield fork(watchDelete);
  yield fork(watchAdd);
  yield fork(watchUpdate);

  yield fork(watchDomains);
}
