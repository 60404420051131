import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultDomainReducers, defaultDomainState } from 'store/defaults';
import purge from 'store/purge';

export default createSlice({
  name: 'USER_ASSETS_DOMAIN',
  initialState: defaultDomainState,
  reducers: defaultDomainReducers,
  extraReducers: {
    ...purge(defaultDomainState),
  },
});

export const FETCH_USER_ASSETS = createAction('FETCH_USER_ASSETS');
