import { takeLatest, put } from 'redux-saga/effects';
import slice, { FETCH_STRIPE_PRODUCTS } from 'store/stripe/products/slice';
import { getStripeProducts } from 'resources/services/stripeService';

function* fetch({ payload }) {
  const { setStripeProducts, fetching, error, success } = slice.actions;

  yield put(fetching());
  try {
    const res = yield getStripeProducts(payload);

    yield put(setStripeProducts(res));
    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchFetchProducts() {
  yield takeLatest(FETCH_STRIPE_PRODUCTS.type, fetch);
}
