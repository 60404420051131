import { combineReducers } from '@reduxjs/toolkit';
import assetsSlice from './domains/assets/slice';
import usersSlice from './slice';

const domainReducers = combineReducers({
  assets: assetsSlice.reducer,
});

export default combineReducers({
  entity: usersSlice.reducer,
  domains: domainReducers,
});
