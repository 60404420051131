import { takeLatest, fork } from 'redux-saga/effects';
import tripPositionsSlice, { FETCH_TRIP_POSITIONS } from './slice';
import entityRequest from 'store/entities/xEntityRequest';
import successResponder from './responders/successResponder';

function* watchFetch() {
  const { error, domainFetching } = tripPositionsSlice.actions;
  yield takeLatest(FETCH_TRIP_POSITIONS.type, entityRequest, {
    actions: { error, domainFetching },
    responder: successResponder,
  });
}

export default function* userSaga() {
  yield fork(watchFetch);
}
