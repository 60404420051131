import client from "configurations/fetch";

// SetEventThresholds
export const setEventThresholds = (request) => {
  return client.post(`/command/SetEventThresholds`, request);
};

export const getWifiLocations = (request) => {
  return client.post(`/command/scanhomewifi`, request);
};

export const saveWifiLocations = (request) => {
  return client.post(`/Command/SetHomeWiFiLocationSettings`, request);
};

export const saveBeaconLocations = (request) => {
  return client.post(`/Command/SetBeaconLocationSettings`, request);
};

export const getDeviceFirmwareConfig = (request) => {
  return client.post(`/Command/GetConfig`, request);
};

export const updateDeviceFirmware = (request) => {
  return client.post(`/Command/UpdateFirmware`, request);
};

export const pollDevicePosition = ({ uid, aid }) => {
  return client.post(`/Command/PollPosition`, { uid, aid });
};

export const startRecoveryMode = ({
  uid,
  aid,
  intervalInSeconds,
  durationInMinutes,
}) => {
  return client.post(`/Command/StartRecoveryMode`, {
    uid,
    aid,
    intervalInSeconds,
    durationInMinutes,
  });
};

export const stopRecoveryMode = ({ uid, aid }) => {
  return client.post(`/Command/StopRecoveryMode`, { uid, aid });
};

export const setDeviceGeofenceSettings = (data) => {
  return client.post(`/Command/SetDeviceGeofenceSettings`, data);
};
