import client from 'configurations/fetch';

export const getSchedules = () => client.get('/schedules');

export const getSchedulesByTarget = (target) => client.get(`/schedules/target/${target}`);

export const getSchedule = (schid) => client.get(`/schedules/${schid}`);

export const addSchedule = (schedule) => client.post(`/schedules/AddSchedule`, schedule);

export const updateSchedule = (schedule) => client.post(`/schedules/UpdateSchedule`, schedule);

export const getScheduleUseCount = (schid, target) => {
  return client.get(`/schedules/GetScheduleUseCount/${schid}/${target}`);
};

export const deleteSchedule = (schid) => client.delete(`/schedules/Delete/${schid}`);

export const assignScheduleToServerGeoplaces = (request) =>
  client.post(`/schedules/AssignScheduleToServerGeoFences`, request);

export const assignAssetsToSchedule = (request) =>
  client.post(`/schedules/AssignAssetsToSchedule`, request);

export const getSchedulesAssignedAssets = (schid) =>
  client.get(`/schedules/GetScheduleAssignedAssets/${schid}`);

export const getSchedulesAssignedGeoplaces = (schid) =>
  client.get(`/schedules/GetScheduleAssignedServerGeoFences/${schid}`);

export const getScheduleEvents = (schid) => client.get(`/schedules/GetScheduleEvents/${schid}`);
