import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultDomainReducers, defaultDomainState } from 'store/defaults';
import purge from 'store/purge';

export default createSlice({
  name: 'TRIPS_POSITIONS_DOMAIN',
  initialState: defaultDomainState,
  reducers: defaultDomainReducers,
  extraReducers: {
    ...purge(defaultDomainState),
  },
});

export const FETCH_TRIP_POSITIONS = createAction('FETCH_TRIP_POSITIONS');
