import styled from 'styled-components/macro';
import { bootstrap } from 'styles/theme';

export const MiniSubtitle = styled.small`
  font-size: 11px;
  color: ${bootstrap.medium};
`;

export const SmallSubtitle = styled.small`
  color: ${bootstrap.medium};
`;

export const SmallTitle = styled.small`
  color: ${bootstrap.dark};
  font-weight: bold;

  font-size: 11px;
`;

export const SmallText = styled.small`
  color: ${bootstrap.dark};

  font-size: 11px;
`;

export const MediumTitle = styled.small`
  color: ${bootstrap.dark};
  font-weight: bold;

  font-size: 13px;
`;

export const Title = styled.h5`
  font-weight: bold;
  color: ${bootstrap.dark};
  margin: 0;
`;

export const Paragraph = styled.p`
  line-height: ${bootstrap.spacer_four};
  color: ${bootstrap.dark_gray};
`;
