import { createSlice, createAction } from "@reduxjs/toolkit";
import { getDeviceById } from "resources/services/deviceService";
import { defaultEntityReducers, defaultEntityState } from "store/defaults";
import purge from "store/purge";
import { emptyObj } from "utilities/empties";

export default createSlice({
  name: "TRIPS_ENTITY",
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload
        ? payload.entities.trips
          ? payload.entities.trips
          : emptyObj
        : state.data;
    },

    successTripPositions: (state, { payload }) => {
      const tripId = payload[0].tripId;
      state.data[tripId] = { ...state.data[tripId], positions: payload };
    },

    clearTrips: (state) => {
      state.data = emptyObj;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_TRIPS = createAction("FETCH_TRIPS");
export const UPDATE_TRIP = createAction("UPDATE_TRIP");
export const SHARE_TRIP = createAction("SHARE_TRIP");
