import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultEntityState, defaultEntityReducers } from 'store/defaults';
import purge from 'store/purge';
import { emptyArr } from 'utilities/empties';

export default createSlice({
  name: 'SCHEDULES_GEOPLACES_DOMAIN',
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: {
      reducer: (state, { payload, meta }) => {
        state.isLoading = false;
        if (!payload) return;

        const { schid } = meta;
        const geoplaces = payload.entities.geoplaces;

        if (!geoplaces) {
          state.data[schid] = emptyArr;
          return;
        }

        state.data[schid] = geoplaces
          ? Object.keys(geoplaces).map(key => parseInt(key))
          : state.data[schid];
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },

    updateSuccess: {
      reducer: (state, { payload, meta }) => {
        state.isLoading = false;
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_SCHEDULE_GEOPLACES = createAction(
  'FETCH_SCHEDULE_GEOPLACES',
  (payload, meta) => ({ payload, meta })
);
export const UPDATE_SCHEDULE_GEOPLACE = createAction(
  'UPDATE_SCHEDULE_GEOPLACE',
  (payload, meta) => ({ payload, meta })
);
