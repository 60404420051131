import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

// NOTE: this is unfortunately necessary as the error can be returned in so many different formats...
export default function getClientError(e) {
  // Check for null or undefined
  if (e === null || e === undefined) return i18n._(t`Unknown error occurred.`);

  // Handle objects (including potential JSON strings)
  if (typeof e === "object") {
    // Use optional chaining for nested properties
    const errorMessage =
      e.error?.message || e.data?.message || e.message || e.error || e.data;
    return typeof errorMessage === "string"
      ? errorMessage
      : i18n._(t`Unknown error occurred.`);
  }

  // Handle strings (check if it's JSON)
  if (typeof e === "string") {
    try {
      const parsed = JSON.parse(e);
      // If parsed is an object, recursively call getClientError
      if (typeof parsed === "object") {
        return getClientError(parsed);
      }
    } catch (error) {
      // If it's not JSON, return the string itself
      return e;
    }
  }

  // Default case for types other than object or string
  return i18n._(t`Unknown error occurred.`);
}
