import { takeLatest, put } from "redux-saga/effects";
import slice, {
  GET_FIRMWARE_CONFIG,
} from "store/entities/assets/domains/deviceDetails/slice";
import { getDeviceFirmwareConfig } from "resources/services/commandService";
import getClientError from "utilities/getClientError";

function* fetch({ payload, meta }) {
  const { firmwareConfigSucess, fetchingFirmwareConfig, error } = slice.actions;

  yield put(fetchingFirmwareConfig());

  try {
    const response = yield getDeviceFirmwareConfig(payload);

    if (response?.error) throw new Error(response.error);

    yield put(firmwareConfigSucess(response));
    meta?.onSuccess && meta.onSuccess(response);
  } catch (e) {
    const message = getClientError(e);

    yield put(error(message));
    meta?.onError && meta.onError(message);
  }
}

export default function* watchFetchFirmwareConfig() {
  yield takeLatest(GET_FIRMWARE_CONFIG.type, fetch);
}
