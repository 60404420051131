import "core-js/web";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "styles/global.scss";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App/App";
import { SENTRY_DSN_URL } from "configurations/appConfig";
import * as serviceWorker from "./serviceWorker";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "configurations/redux";
import { RippleSpinner } from "styles/components";
import Providers from "providers";
import { en as pluralEn, fr as pluralFr } from "make-plural/plurals";
import { i18n } from "@lingui/core";
import catalogEn from "utilities/locales/en/messages.js";
import catalogFr from "utilities/locales/fr/messages.js";
import catalogPl from "utilities/locales/pl/messages.js";
import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
  "e9bc8d3ddc33de1b28452704725c351aTz04NjM0NCxFPTE3NDE5MDE4ODYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

Sentry.init({
  dsn: SENTRY_DSN_URL,
});

i18n.loadLocaleData("en", { plurals: pluralEn });
i18n.loadLocaleData("fr", { plurals: pluralFr });

i18n.load({
  en: catalogEn.messages,
  fr: catalogFr.messages,
  pl: catalogPl.messages,
});

i18n.activate("en");
localStorage.setItem("language", "en");

ReactDOM.render(
  <BrowserRouter>
    <Providers>
      <PersistGate loading={<RippleSpinner />} persistor={persistor}>
        <App />
      </PersistGate>
    </Providers>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
