import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultEntityReducers, defaultEntityState } from 'store/defaults';
import purge from 'store/purge';
import { emptyArr } from 'utilities/empties';

export default createSlice({
  name: 'SCHEDULED_REPORTS_ENTITY',
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload ? payload.entities.scheduledReports || emptyArr : emptyArr;
    },

    updateSuccess: state => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_SCHEDULED_REPORTS = createAction('FETCH_SCHEDULED_REPORTS');
export const DELETE_SCHEDULED_REPORT = createAction('DELETE_SCHEDULED_REPORT');
export const ADD_SCHEDULED_REPORT = createAction('ADD_SCHEDULED_REPORT');
export const UPDATE_SCHEDULED_REPORT = createAction('UPDATE_SCHEDULED_REPORT');
