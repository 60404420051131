import axios from "axios";
import { NODE_API_URL } from "./appConfig";
import { firebaseAuth } from "./firebase";

const nodeClient = axios.create({
  baseURL: NODE_API_URL,
  headers: { Accept: "application/json" },
});

nodeClient.interceptors.request.use(
  async (config) => {
    if (firebaseAuth?.currentUser) {
      const token = await firebaseAuth.currentUser.getIdToken(true);
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

nodeClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default nodeClient;
