import styled from "styled-components/macro";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { bootstrap } from "styles/theme";

export const StyledModal = styled(Modal)``;

export const StyledModalBody = styled(Modal.Body)`
  background: ${bootstrap.light};
`;

export const StyledModalHeader = styled(Modal.Header)`
  display: flex;
  justify-content: center;
`;

export const StyledModalFooter = styled(Modal.Footer)`
  min-height: 66.2px;
  background: white;
`;

export const StyledModalTitle = styled(Modal.Title)`
  color: ${bootstrap.dark};
  font-weight: 400;
`;

export const StyledButton = styled(Button)`
  background: ${bootstrap.light};
  border: 1px solid ${bootstrap.dark} !important;
  color: ${bootstrap.dark} !important;
`;
export const StyledSubmitButton = styled(Button)`
  background: ${bootstrap.primary};
`;
