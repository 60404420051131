import { takeLatest, put } from "redux-saga/effects";
import getClientError from "utilities/getClientError";
import { getAvailableSolutionPlans } from "resources/services/solutionPlanService";
import slice, { FETCH_AVAILABLE_SOLUTION_PLANS } from "./slice";

function* fetch({ payload, meta }) {
  const { did } = payload;
  const { success, fetching, error } = slice.actions;

  yield put(fetching());

  try {
    const response = yield getAvailableSolutionPlans(did);

    yield put(success(response));
    meta?.onSuccess && meta.onSuccess(response);
  } catch (e) {
    const message = getClientError(e);

    yield put(error(message));
    meta?.onError && meta.onError(message);
  }
}

export default function* watchFetchAvailableSolutionPlans() {
  yield takeLatest(FETCH_AVAILABLE_SOLUTION_PLANS.type, fetch);
}
