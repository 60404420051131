import {
  startOfDay,
  endOfDay,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  subDays,
  endOfWeek,
} from "date-fns";
import { subWeeks } from "date-fns/esm";
import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";

export default () => {
  const today = new Date();

  return [
    {
      value: { startDate: startOfDay(today), endDate: endOfDay(today) },
      label: i18n._(t`Today`),
      range: "today",
    },
    {
      value: {
        startDate: startOfDay(subDays(today, 1)),
        endDate: endOfDay(subDays(today, 1)),
      },
      label: i18n._(t`Yesterday`),
      range: "yesterday",
    },
    {
      value: { startDate: startOfWeek(today), endDate: today },
      label: i18n._(t`This Week`),
      range: "this week",
    },
    {
      value: {
        startDate: startOfWeek(subWeeks(today, 1)),
        endDate: endOfWeek(subWeeks(today, 1)),
      },
      label: i18n._(t`Last Week`),
      range: "last week",
    },
    {
      value: { startDate: startOfMonth(today), endDate: today },
      label: i18n._(t`This Month`),
      range: "this month",
    },
    {
      value: {
        startDate: startOfMonth(subMonths(today, 1)),
        endDate: endOfMonth(subMonths(today, 1)),
      },
      label: i18n._(t`Last Month`),
      range: "last month",
    },
  ];
};
