import { createSlice } from "@reduxjs/toolkit";
import datePeriodOptions from "components/Select/options/datePeriodOptions";
import purge from "store/purge";

const defaultState = {
  isShowingMapSettings: false,
  isShowingGeoplaces: false,
  isShowingClusterCard: true,
  isShowingTable: false,

  isDrawingSearchArea: false,
  isAutofit: true,
  isLiveMap: true,
  isShowingTag: true,
  isClustering: false,

  period: datePeriodOptions()[2],
  tagKey: "nickname",
};

export default createSlice({
  name: "ASSET_MAP_SETTINGS_UI",
  initialState: defaultState,
  reducers: {
    setPeriod: (state, { payload }) => {
      state.period = payload;
    },

    toggleIsShowingMapSettings: (state, { payload }) => {
      state.isShowingMapSettings = payload
        ? payload
        : !state.isShowingMapSettings;
    },

    toggleIsShowingTable: (state, { payload }) => {
      state.isShowingTable = payload ? payload : !state.isShowingTable;
    },

    toggleIsShowingGeoplaces: (state) => {
      state.isShowingGeoplaces = !state.isShowingGeoplaces;
    },

    toggleIsDrawingSearchArea: (state) => {
      state.isDrawingSearchArea = !state.isDrawingSearchArea;
    },

    toggleIsShowingTag: (state) => {
      state.isShowingTag = !state.isShowingTag;
    },

    toggleIsLiveMap: (state) => {
      state.isLiveMap = !state.isLiveMap;
    },

    toggleIsAutoFit: (state) => {
      state.isAutofit = !state.isAutofit;
    },

    toggleIsShowingClusterCard: (state, { payload }) => {
      state.isShowingClusterCard = payload || !state.isShowingClusterCard;
    },

    toggleIsClustering: (state, { payload }) => {
      state.isClustering = payload || !state.isClustering;
    },

    setTagKey: (state, { payload }) => {
      state.tagKey = payload;
    },

    reset: () => defaultState,
  },
  extraReducers: {
    ...purge(defaultState),
  },
});
