import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import rootReducer from "store/rootReducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "store/rootSaga";
import storageLocal from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage: storageLocal,
  whitelist: ["account"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const defaultMiddleware = getDefaultMiddleware({ immutableCheck: false });
// remove serializable state middleware
defaultMiddleware.pop();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [...defaultMiddleware, sagaMiddleware],
  devTools: {
    actionsDenylist: ["setHoveredAssetId", "setHoveredPositionId"],
  },
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

// NOTE: you can expose these for development visibility
// window.persistor = persistor;
// window.store = store;
