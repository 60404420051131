import { takeLatest, put } from "redux-saga/effects";
import loginSlice, { UPDATE_MY_USER } from "../slice";
import { normalize } from "normalizr";
import schema from "store/entities/users/schema";

function* update({ payload: { reqFn, onSuccess, onError } }) {
  const { fetching, error, success, setMyUser } = loginSlice.actions;

  yield put(fetching());

  try {
    const response = yield reqFn();

    const normalized = normalize(response, schema);

    yield put(success());
    yield put(setMyUser(response));

    onSuccess && onSuccess({ response, normalized });
  } catch (e) {
    yield put(error(e));
    onError && onError(e);
  }
}

export default function* watchUpdate() {
  yield takeLatest(UPDATE_MY_USER.type, update);
}
