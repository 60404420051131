import { takeLatest, put } from "redux-saga/effects";
import accountSlice, { RESET_PASSWORD } from "store/account/slice";
import { resetPassword } from "resources/services/userService";
import getClientError from "utilities/getClientError";

function* saga({ payload, meta }) {
  const { username } = payload;
  const { success, fetching } = accountSlice.actions;

  yield put(fetching());
  try {
    const response = yield resetPassword(username);
    if (response?.error) {
      throw new Error(response.error);
    }
    meta?.onSuccess && meta.onSuccess(username);
  } catch (e) {
    const message = getClientError(e);
    meta?.onError && meta.onError(message);
  } finally {
    yield put(success());
  }
}

export default function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD.type, saga);
}
