import { createSlice, createAction } from "@reduxjs/toolkit";
import { defaultEntityReducers, defaultEntityState } from "store/defaults";
import purge from "store/purge";
import { emptyObj } from "utilities/empties";

export default createSlice({
  name: "BEACONS_LOCATIONS_DOMAIN",
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,
    scanResultSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload
        ? payload.entities.beaconLocations || emptyObj
        : emptyObj;
    },
    success: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const REQUEST_BEACON_SCAN = createAction("REQUEST_BEACON_SCAN");
export const SAVE_BEACON_LOCATIONS = createAction("SAVE_BEACON_LOCATIONS");
export const FETCH_BEACON_SCAN_RESULTS = createAction(
  "FETCH_BEACON_SCAN_RESULTS"
);
