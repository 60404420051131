import { createSlice, createAction } from "@reduxjs/toolkit";
import { defaultEntityReducers, defaultEntityState } from "store/defaults";
import purge from "store/purge";
import { emptyObj } from "utilities/empties";

export default createSlice({
  name: "WIFI_LOCATIONS_DOMAIN",
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,
    scanResultSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload
        ? payload.entities.wifiLocations || emptyObj
        : emptyObj;
    },
    success: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const REQUEST_WIFI_SCAN = createAction("REQUEST_WIFI_SCAN");
export const SAVE_WIFI_LOCATIONS = createAction("SAVE_WIFI_LOCATIONS");
export const FETCH_WIFI_SCAN_RESULTS = createAction("FETCH_WIFI_SCAN_RESULTS");
