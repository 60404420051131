import { createSlice } from "@reduxjs/toolkit";
import purge from "store/purge";
import setValueToLocalStorage from "utilities/localStorage/setValueToLocal";

const defaultState = {
  isGrouped: true,

  focusedAssetIds: [],
  collapsedGroupIds: [],

  sortOptions: {
    sortKey: "nickname",
    sortType: "string",
    isAscending: true,
  },
};

export default createSlice({
  name: "ASSET_LIST_UI",
  initialState: defaultState,
  reducers: {
    toggleIsGrouped: (state, { payload }) => {
      const isGrouped =
        typeof payload === "boolean" ? payload : !state.isGrouped;

      state.isGrouped = isGrouped;

      setValueToLocalStorage("isGrouped", isGrouped);
    },

    setIsGrouped: (state, { payload }) => {
      state.isGrouped = payload;
    },

    setFocusedAssetIds: (state, { payload }) => {
      state.focusedAssetIds = Array.isArray(payload) ? payload : [payload];
    },

    removeFocusedAssetIds: (state, { payload }) => {
      const newFocusedAssetIds = state.focusedAssetIds.filter(
        (id) => !payload.includes(id)
      );

      state.focusedAssetIds = newFocusedAssetIds;
    },

    toggleFocusedAssetId: (state, { payload }) => {
      const toggledIds = state.focusedAssetIds.includes(payload)
        ? state.focusedAssetIds.filter((id) => id !== payload)
        : [...state.focusedAssetIds, payload];

      state.focusedAssetIds = toggledIds;
    },

    clearFocusedAssetIds: (state) => {
      state.focusedAssetIds = [];
    },

    setSortOptions: (state, { payload }) => {
      const newOptions = { ...state.sortOptions, ...payload };
      state.sortOptions = newOptions;

      setValueToLocalStorage("isAscending", newOptions.isAscending);
      setValueToLocalStorage("sortKey", newOptions.sortKey);
      setValueToLocalStorage("sortType", newOptions.sortType);
    },

    toggleIsAscending: (state, { payload }) => {
      state.sortOptions.isAscending =
        typeof payload === "boolean" ? payload : !state.sortOptions.isAscending;
    },

    toggleFocusedAssetGroup: (state, { payload }) => {
      const toggledIds = payload.every((aid) =>
        state.focusedAssetIds.includes(aid)
      )
        ? state.focusedAssetIds.filter((id) => !payload.includes(id))
        : [
            ...state.focusedAssetIds,
            ...payload.filter((id) => !state.focusedAssetIds.includes(id)),
          ];

      state.focusedAssetIds = toggledIds;
    },

    toggleCollapsedGroup: (state, { payload }) => {
      const toggledIds = state.collapsedGroupIds.includes(payload)
        ? state.collapsedGroupIds.filter((id) => id !== payload)
        : [...state.collapsedGroupIds, payload];

      state.collapsedGroupIds = toggledIds;
    },

    toggleAllCollapsedGroupIds: (state, { payload }) => {
      const toggledIds =
        state.collapsedGroupIds.length < payload.length ? payload : [];

      state.collapsedGroupIds = toggledIds;
    },

    reset: () => defaultState,
  },
  extraReducers: {
    ...purge(defaultState),
  },
});
