import { put } from "redux-saga/effects";
import tripPositionsSlice from "../slice";
import tripSlice from "store/entities/trips/slice";

export default function* successResponder({ response }) {
  const { successTripPositions: success } = tripSlice.actions;
  const { domainSuccess } = tripPositionsSlice.actions;

  yield put(domainSuccess());
  yield put(success(response.positions));
}
