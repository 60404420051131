import styled from 'styled-components/macro';
import { Col, Row } from 'react-bootstrap';
import { bootstrap } from 'styles/theme';

export const StyledLabelCol = styled(Col)`
  display: flex;
  align-items: center;
  margin: 0 !important;
`;

export const StyledFormGroupRow = styled(Row)`
  justify-content: center;
  margin-bottom: ${bootstrap.spacer_two};
`;
