import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { useMemo, useCallback } from "react";
import { selectAccessToken, selectExpiresAt } from "store/account/selectors";

export default function useAuthorization() {
  const accessToken = useSelector(selectAccessToken);
  const expiresAt = useSelector(selectExpiresAt);

  const decodedToken = useMemo(
    () => (accessToken ? jwtDecode(accessToken) : ""),
    [accessToken]
  );

  const isTokenValid = useCallback(() => {
    return new Date().getTime() <= expiresAt;
  }, [expiresAt]);

  const isAuthenticated = useMemo(
    () => !!accessToken && isTokenValid(),
    [accessToken, isTokenValid]
  );

  return { decodedToken, isAuthenticated };
}
