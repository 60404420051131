import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// NOTE: A custom hook that builds on useLocation to parse the query string for you.
export default function useQuery() {
  const location = useLocation();
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);
  return query;
}
