import React, { useEffect, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import LoginContainer from "components/LoginContainer/LoginContainer";
import { selectLanguage } from "store/account/selectors";
import useNewSession from "hooks/authorization/useNewSession";
import AnonymousUser from "components/AnonymousUser/AnonymousUser";
import { Helmet } from "react-helmet";
import { favicon } from "styles/theme";
import { i18n } from "@lingui/core";
import MaterialSnackbar from "styles/components/mui/MaterialSnackbar";
import RegisterContainer from "components/RegisterContainer/RegisterContainer";
import useLanguageFromQuery from "hooks/utilities/useLanguageFromQuery";
import Logout from "components/Account/Logout/Logout";
import useAuthorization from "hooks/authorization/useAuthorization";
import useSentryFeedbackWidget from "hooks/utilities/useSentryFeedback";
import ApplicationLoader2 from "components/ApplicationLoader/ApplicatedLoader2";
import Contact from "components/Contact/Contact";

// Lazy load Layout and MobileStepper
const LazyLayout = lazy(() => import("components/Layout/Layout"));
const LazyMobileStepper = lazy(() =>
  import("components/MobileStepper/MobileStepper")
);

export default function App() {
  const language = useSelector(selectLanguage);
  const { isAuthenticated } = useAuthorization();

  useSentryFeedbackWidget();

  useNewSession();
  useLanguageFromQuery();

  // NOTE: update active language
  useEffect(() => {
    i18n.activate(language.value);
  }, [language]);

  return (
    <>
      <Suspense fallback={<ApplicationLoader2 />}>
        <Switch>
          {/* Redirect to app from login if authenticated */}
          <Route
            exact
            path="/"
            component={() =>
              isAuthenticated ? (
                <Redirect to="/Assets" />
              ) : (
                <Redirect to="/login" />
              )
            }
          />

          <Route path="/contact" component={Contact} />
          <Route path="/login" component={LoginContainer} />
          <Route path="/register" component={RegisterContainer} />
          <Route
            path="/anonymousUser/:username/:password"
            component={AnonymousUser}
          />
          <Route
            exact
            path="/stepper/:step/:type?"
            component={LazyMobileStepper}
          />
          <PrivateRoute exact path="/logout" component={Logout} />
          <PrivateRoute
            path="/"
            permissions="assets.read"
            component={LazyLayout}
          />
          {/* Catch-all route for 404 handling */}
          <Route
            render={() =>
              isAuthenticated ? (
                <Redirect to="/Assets" />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
        </Switch>
      </Suspense>

      <MaterialSnackbar />

      <Helmet>
        <link rel="shortcut icon" id="favicon" href={favicon} />
      </Helmet>
    </>
  );
}
