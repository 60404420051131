import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useAuthorization from 'hooks/authorization/useAuthorization';
import BadPermissions from 'components/Account/BadPermissions/BadPermissions';
import useHasPermission from 'hooks/account/useHasPermission';

export default function PrivateRoute({ permissions, path, ...rest }) {
  const hasPermission = useHasPermission(permissions);
  const { isAuthenticated } = useAuthorization();

  if (!isAuthenticated) return <Redirect to={'/login'} />;
  if (!hasPermission) return <BadPermissions permissions={permissions} path={path} />;

  return <Route path={path} {...rest} />;
}
