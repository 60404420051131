import { takeLatest } from "redux-saga/effects";
import { RESEND_REGISTER_EMAIL } from "store/account/slice";
import { resendRegisterEmail } from "resources/services/userService";

function* fetch({ payload, meta }) {
  try {
    const response = yield resendRegisterEmail(payload);
    meta?.onSuccess && meta.onSuccess(response);
  } catch (e) {
    meta?.onSuccess && meta.onError(e);
  }
}

export default function* watchResendRegisterEmail() {
  yield takeLatest(RESEND_REGISTER_EMAIL.type, fetch);
}
