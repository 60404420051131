import { takeLatest, takeEvery, fork } from "redux-saga/effects";
import maintenanceAlertsSlice, {
  ADD_MAINTENANCE_ALERT,
  FETCH_MAINTENANCE_ALERTS,
  DELETE_MAINTENANCE_ALERT,
  UPDATE_MAINTENANCE_ALERT,
} from "./slice";
import schema from "./schema";
import entityRequest from "store/entities/xEntityRequest";

const actions = maintenanceAlertsSlice.actions;

function* watchFetch() {
  yield takeLatest(FETCH_MAINTENANCE_ALERTS.type, entityRequest, {
    actions,
    schema,
  });
}

function* watchUpdate() {
  yield takeEvery(UPDATE_MAINTENANCE_ALERT.type, entityRequest, {
    actions: { ...actions, success: actions.updateSuccess },
  });
}

function* watchDelete() {
  yield takeLatest(DELETE_MAINTENANCE_ALERT.type, entityRequest, { actions });
}

function* watchAdd() {
  yield takeLatest(ADD_MAINTENANCE_ALERT.type, entityRequest, { actions });
}

export default function* maintenanceAlertsSaga() {
  yield fork(watchFetch);
  yield fork(watchDelete);
  yield fork(watchAdd);
  yield fork(watchUpdate);
}
