import { createSlice, createAction } from '@reduxjs/toolkit';
import purge from 'store/purge';

const initialState = {
  subscriptionItems: [],

  isLoading: false,
};

export default createSlice({
  name: 'STRIPE_SUBSCRIPTION_ITEMS',
  initialState,
  reducers: {
    fetching: (state) => {
      state.isLoading = true;
    },

    error: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    success: (state) => {
      state.isLoading = false;
    },

    setSubscriptionItems: (state, { payload }) => {
      state.subscriptionItems = payload;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const FETCH_STRIPE_SUBSCRIPTION_ITEMS = createAction('FETCH_STRIPE_SUBSCRIPTION_ITEMS');
export const UPDATE_STRIPE_SUBSCRIPTION_ITEM = createAction(
  'UPDATE_STRIPE_SUBSCRIPTION_ITEM',
  (payload, meta) => ({
    payload,
    meta,
  })
);
export const DELETE_STRIPE_SUBSCRIPTION_ITEM = createAction('DELETE_STRIPE_SUBSCRIPTION_ITEM');
export const CREATE_STRIPE_SUBSCRIPTION_ITEM = createAction('CREATE_STRIPE_SUBSCRIPTION_ITEM');
