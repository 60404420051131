import { createSelector } from 'reselect';
import { emptyObj } from 'utilities/empties';

export const selectGroups = state => state.entities.groups.data;
export const selectGroupsAsArray = createSelector([selectGroups], groups => Object.values(groups));
export const selectIsLoadingGroups = state => state.entities.groups.isLoading;

export const buildSelectGroupsByIds = ids =>
  createSelector([selectGroups], groups => ids.map(id => groups[id]));

export const buildSelectGroupById = gid =>
  createSelector([selectGroups], groups => (groups && gid ? groups[gid] : emptyObj));
