import { createSlice } from '@reduxjs/toolkit';
import purge from 'store/purge';

const defaultState = {
  focusedGeoplaceIds: [],

  sortOptions: {
    sortKey: 'name',
    isAscending: true,
    sortType: 'string',
  },
};

export default createSlice({
  name: 'GEOPLACES_UI',
  initialState: defaultState,
  reducers: {
    setFocusedGeoplaceIds: (state, { payload }) => {
      state.focusedGeoplaceIds = payload;
    },

    addFocusedGeoplaceIds: (state, { payload }) => {
      payload
        .filter((id) => !state.focusedGeoplaceIds.includes(id))
        .forEach((id) => state.focusedGeoplaceIds.push());
    },

    clearFocusedGeoplaceIds: (state) => {
      state.focusedGeoplaceIds = [];
    },

    toggleFocusedGeoplaceId: (state, { payload }) => {
      const toggledIds = state.focusedGeoplaceIds.includes(payload)
        ? state.focusedGeoplaceIds.filter((id) => id !== payload)
        : [...state.focusedGeoplaceIds, payload];

      state.focusedGeoplaceIds = toggledIds;
    },

    setSortOptions: (state, { payload }) => {
      state.sortOptions = { ...state.sortOptions, ...payload };
    },

    toggleIsAscending: (state, { payload }) => {
      state.sortOptions.isAscending =
        typeof payload === 'boolean' ? payload : !state.sortOptions.isAscending;
    },
  },
  extraReducers: {
    ...purge(defaultState),
  },
});
