import { takeLatest, fork, put } from 'redux-saga/effects';
import deviceEventsSlice, { FETCH_DEVICE_EVENTS, FETCH_SCHEDULES_DEVICE_EVENTS } from './slice';
import { getCompanyActionCodeOverride } from 'resources/services/companyService';
import { getScheduleEvents } from 'resources/services/scheduleService';

function* fetchSaga({ payload }) {
  const { success, error, fetching } = deviceEventsSlice.actions;

  yield put(fetching());
  try {
    const response = yield getCompanyActionCodeOverride(payload);

    yield put(success(response));
  } catch (e) {
    const message = e && e.data && e.data.error;
    yield put(error(message));
  }
}

function* watchFetch() {
  yield takeLatest(FETCH_DEVICE_EVENTS.type, fetchSaga);
}

function* fetchSchedulesDeviceEvents({ payload }) {
  const { setCurrentSchedulesDeviceEvents, error, fetching } = deviceEventsSlice.actions;

  yield put(fetching());
  try {
    const response = yield getScheduleEvents(payload);

    yield put(setCurrentSchedulesDeviceEvents(response));
  } catch (e) {
    const message = e && e.data && e.data.error;
    yield put(error(message));
  }
}

function* watchFetchCurrentSchedulesDeviceEvents() {
  yield takeLatest(FETCH_SCHEDULES_DEVICE_EVENTS.type, fetchSchedulesDeviceEvents);
}

export default function* deviceEventsSaga() {
  yield fork(watchFetch);
  yield fork(watchFetchCurrentSchedulesDeviceEvents);
}
