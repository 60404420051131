import { createSlice, createAction } from "@reduxjs/toolkit";
import languageOptions from "components/Select/options/languageOptions";
import purge from "store/purge";

const initialState = {
  isLoading: false,
  isLoadingMyCompany: false,

  myUser: {},
  myCompany: {},
  permissions: {},

  mapSettings: {
    autoFit: true,
    geoplaces: false,
    cluster: false,
    showTags: true,
    tableView: false,
    liveMap: true,
    showCard: false,
    tags: { name: true, location: false, status: false, speed: false },
    mapDefaults: {
      mapCentreLat: null,
      mapCentreLng: null,
      mapZoom: null,
      clusterRadius: null,
    },
    trafficLayer: false,
  },

  avatar: undefined,

  documents: undefined,

  language: languageOptions[0],

  accessToken: undefined,
  expiresAt: undefined,

  accessTokenASP: undefined,
  expiresAtASP: undefined,

  anonymousUserPass: undefined,

  loginStatus: "",
};

export default createSlice({
  name: "ACCOUNT",
  initialState,
  reducers: {
    fetching: (state) => {
      state.isLoading = true;
    },

    fetchingMyCompany: (state) => {
      state.isLoadingMyCompany = true;
    },

    error: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingMyCompany = false;
      state.error = payload;
    },

    success: (state) => {
      state.isLoading = false;
      state.isLoadingMyCompany = false;
      state.error = undefined;
    },

    setLoginStatus: (state, { payload }) => {
      state.loginStatus = payload;
    },

    setPermissions: (state, { payload }) => {
      state.permissions = payload;
    },

    setMapSettings: (state, { payload }) => {
      state.mapSettings = { ...state.mapSettings, ...payload };
    },

    setAnonymousUserPass: (state, { payload }) => {
      state.anonymousUserPass = payload;
    },

    setMyUser: (state, { payload }) => {
      // NOTE: no longer used
      payload.accountStates.updatePaymentMethod = false;
      // NOTE: remove account states when they are all false
      payload.accountStates &&
        !Object.values(payload.accountStates).some((state) => !!state) &&
        delete payload.accountStates;

      state.myUser = payload;
    },

    setMyAvatar: (state, { payload }) => {
      state.avatar = payload;
    },

    setMyCompany: (state, { payload }) => {
      state.myCompany = payload;
    },

    setDocuments: (state, { payload }) => {
      state.documents = payload;
    },

    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },

    setLanguage: (state, { payload }) => {
      state.language = payload;
    },

    setAccessToken: (state, { payload }) => {
      state.accessToken = payload;
    },

    setExpiresAt: (state, { payload }) => {
      state.expiresAt = payload;
    },

    setAccessTokenASP: (state, { payload }) => {
      state.accessTokenASP = payload;
    },

    setExpiresAtASP: (state, { payload }) => {
      state.expiresAtASP = payload;
    },

    clearError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const LOGIN = createAction("LOGIN", (payload, meta) => ({
  payload,
  meta,
}));

export const REGISTER = createAction("REGISTER", (payload, meta) => ({
  payload,
  meta,
}));

export const FETCH_MY_USER = createAction("FETCH_MY_USER");
export const FETCH_MY_COMPANY = createAction("FETCH_MY_COMPANY");
export const FETCH_MY_AVATAR = createAction("FETCH_MY_AVATAR");

export const UPDATE_MY_USER = createAction("UPDATE_MY_USER");
export const UPDATE_MY_COMPANY = createAction(
  "UPDATE_MY_COMPANY",
  (payload, meta) => ({
    payload,
    meta,
  })
);

export const FETCH_DOCUMENTS = createAction("FETCH_DOCUMENTS");

export const RESET_PASSWORD = createAction(
  "RESET_PASSWORD",
  (payload, meta) => ({
    payload,
    meta,
  })
);

export const UPDATE_TUTORIALS = createAction("UPDATE_TUTORIALS");
export const UPDATE_TOKEN = createAction("UPDATE_TOKEN");
export const UPDATE_MAP_SETTINGS = createAction("UPDATE_MAP_SETTINGS");

export const ACCEPT_TRACKING_TERMS = createAction(
  "ACCEPT_TRACKING_TERMS",
  (payload, meta) => ({
    payload,
    meta,
  })
);

export const ACCEPT_TERMS = createAction("ACCEPT_TERMS", (payload, meta) => ({
  payload,
  meta,
}));

export const REJECT_TRACKING_TERMS = createAction(
  "REJECT_TRACKING_TERMS",
  (payload, meta) => ({
    payload,
    meta,
  })
);

export const REJECT_TERMS = createAction("REJECT_TERMS", (payload, meta) => ({
  payload,
  meta,
}));

export const RESEND_REGISTER_EMAIL = createAction(
  "RESEND_REGISTER_EMAIL",
  (payload, meta) => ({
    payload,
    meta,
  })
);

export const IMPERSONATE_USER = createAction(
  "IMPERSONATE_USER",
  (payload, meta) => ({
    payload,
    meta,
  })
);

export const LEAVE_IMPERSONATION = createAction(
  "LEAVE_IMPERSONATION",
  (payload, meta) => ({
    payload,
    meta,
  })
);
