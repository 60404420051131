import { put, select } from 'redux-saga/effects';

export default function* setLoadingFromCache(cacheSelector, actions, type) {
  const cache = yield (cacheSelector && select(cacheSelector));
  const cacheIsArray = Array.isArray(cache)
  const hasCachedContent = cache && ((cacheIsArray && cache.length > 0) 
    || (typeof cache === 'object' && !cacheIsArray && Object.values(cache).length > 0))

  if (hasCachedContent) return;

  const { fetching, domainFetching } = actions;

  yield (domainFetching && put(domainFetching())) ;
  yield (fetching && put(fetching(type))) ;
}
