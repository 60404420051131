import { put } from 'redux-saga/effects';
import { emptyObj } from 'utilities/empties';

export default function* handleSuccess(response, normalized, onSuccess, actions, meta, type) {
  const successMeta = {...(meta ? meta : emptyObj), initiatorType: type }
  const { success, domainSuccess } = actions;
  if (!normalized) {
    onSuccess && onSuccess({ response });

    yield (domainSuccess && put(domainSuccess(undefined, successMeta)));
    yield (success && put(success(undefined, successMeta)));

    return;
  }

  onSuccess && onSuccess({ response, normalized });
  yield (domainSuccess && put(domainSuccess(normalized.result, successMeta)));
  yield (success && put(success(normalized, successMeta)));
}
