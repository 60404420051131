import { takeLatest, put } from "redux-saga/effects";
import slice, {
  DELETE_STRIPE_SUBSCRIPTION_ITEM,
} from "store/stripe/subscriptionItems/slice";
import { deleteStripeSubscriptionItem } from "resources/services/stripeService";
import { FETCH_STRIPE_CUSTOMER } from "store/stripe/customer/slice";

function* fetch({ payload }) {
  const { fetching, error, success } = slice.actions;

  yield put(fetching());
  try {
    yield deleteStripeSubscriptionItem(payload);

    yield put(FETCH_STRIPE_CUSTOMER());
    yield put(success());
  } catch (e) {
    console.log(e);
    yield put(error(e));
  }
}

export default function* watchDeleteSubscriptionItem() {
  yield takeLatest(DELETE_STRIPE_SUBSCRIPTION_ITEM.type, fetch);
}
