import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN } from "store/account/slice";
import { Redirect } from "react-router";
import { selectAccessToken } from "store/account/selectors";
import useLogout from "hooks/account/useLogout";
import accountSlice from "store/account/slice";
import { useHistory } from "react-router";

export default function AnonymousUser({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setAnonymousUserPass } = accountSlice.actions;
  const token = useSelector(selectAccessToken);
  const { username, password } = match.params;
  const logout = useLogout();

  useEffect(() => {
    const pauseForLogout = async () => {
      if (token) await logout();

      dispatch(
        LOGIN(
          {
            username,
            password,
          },
          {
            onSuccess: () => history.push("/Assets"),
          }
        )
      );

      dispatch(setAnonymousUserPass(password));
    };
    pauseForLogout();
  }, [
    dispatch,
    username,
    password,
    logout,
    token,
    setAnonymousUserPass,
    history,
  ]);

  return <Redirect to="/login" />;
}
