import { takeLatest, put } from "redux-saga/effects";
import accountSlice, { ACCEPT_TERMS } from "store/account/slice";
import { acceptTermsAndConditions } from "resources/services/userService";

function* fetch({ payload, meta }) {
  const { uid, url } = payload;
  const { error, success, setMyUser } = accountSlice.actions;
  try {
    const response = yield acceptTermsAndConditions(uid, url);
    yield put(setMyUser(response));

    meta?.onSuccess && meta.onSuccess(response);
    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchAcceptTerms() {
  yield takeLatest(ACCEPT_TERMS.type, fetch);
}
