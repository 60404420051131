import { createSlice } from '@reduxjs/toolkit';
import purge from 'store/purge';

const defaultState = {
  isShowingForgotPasswordForm: false,
  isShowingTermsAndConditionsForm: false,
};

export default createSlice({
  name: 'LOGIN_UI',
  initialState: defaultState,
  reducers: {
    showForgotPasswordForm: state => {
      state.isShowingForgotPasswordForm = true;
    },
    hideForgotPasswordForm: state => {
      state.isShowingForgotPasswordForm = false;
    },
    showTermsAndConditionsForm: state => {
      state.isShowingTermsAndConditionsForm = true;
    },
    hideTermsAndConditionsForm: state => {
      state.isShowingTermsAndConditionsForm = false;
    },
    closeForm: state => ({
      ...state,
      isShowingForgotPasswordForm: false,
      isShowingTermsAndConditionsForm: false,
    }),
  },
  extraReducers: {
    ...purge(defaultState),
  },
});
