import client from "configurations/fetch";

export const getAllDeviceTypes = () => {
  return client.get("/DeviceTypes");
};

export const getAllDevices = () => {
  return client.get("/devices");
};

export const getDeviceById = (did) => {
  return client.get(`/devices/${did}`);
};

export const getDevicesCompany = (cid) => {
  return client.get(`/devices/company/${cid}`);
};

export const getDeviceSettings = (did) => {
  return client.get(`/devices/settings/${did}`);
};

export const registerNewDevice = (device) => {
  return client.post("/Devices/registerNewDevice", device);
};

export const getWifiLocationResults = (did) => {
  return client.get(`/devices/${did}/homewifiscanresults`);
};

export const listDeviceFirmwares = (did) => {
  return client.get(`/devices/${did}/firmware/list`);
};

export const getDeviceFirmwareProgress = (did) => {
  return client.get(`/devices/${did}/firmware/progress`);
};
