import { takeLatest, fork } from 'redux-saga/effects';
import reportsSlice, { FETCH_REPORTS } from './slice';
import schema from './schema';
import { selectReports } from './selectors';
import entityRequest from 'store/entities/xEntityRequest';

function* watchFetch() {
  yield takeLatest(FETCH_REPORTS.type, entityRequest, {
    cacheSelector: selectReports,
    actions: reportsSlice.actions,
    schema,
  });
}

export default function* reportsSaga() {
  yield fork(watchFetch);
}
