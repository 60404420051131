import React from "react";
import RegisterForm from "forms/Register/Register";
import Box from "@mui/material/Box";
import { Route, Switch } from "react-router";
import RegisterSuccess from "./RegisterSuccess/RegisterSuccess";
import { useForm } from "react-hook-form";

export default function RegisterContainer() {
  const form = useForm();
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ margin: "auto" }}>
        <RegisterForm form={form} />
      </Box>

      <Switch>
        <Route
          path="/register/success"
          render={() => <RegisterSuccess form={form} />}
        />
      </Switch>
    </Box>
  );
}
