import { takeLatest, put } from "redux-saga/effects";
import slice, {
  FETCH_STRIPE_CUSTOMER_AS_ADMIN,
} from "store/stripe/customer/slice";
import cartSlice from "store/stripe/cart/slice";
import { createStripeCustomerAsAdmin } from "resources/services/stripeService";

function* fetch({ payload }) {
  const { setStripeCustomer, fetching, error, success } = slice.actions;
  const { setCart } = cartSlice.actions;
  const { firstName, lastName, email, cid } = payload;

  yield put(fetching());

  try {
    const stripeCustomer = yield createStripeCustomerAsAdmin(cid, {
      email,
      name: `${firstName} ${lastName}`,
    });

    const cart = {};
    const subscription = stripeCustomer?.subscriptions?.data[0];

    if (subscription) {
      // NOTE: build the cart based on the customers current subscription items
      subscription.items.data.forEach((item) => {
        const priceId = item.price.id;
        const assetIds = item.metadata?.assetIds;

        if (assetIds) cart[priceId] = assetIds.split(",");
      });
    }

    yield put(setCart(cart));
    yield put(setStripeCustomer(stripeCustomer));
    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchFetchCustomerAsAdmin() {
  yield takeLatest(FETCH_STRIPE_CUSTOMER_AS_ADMIN.type, fetch);
}
