import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultEntityState, defaultEntityReducers } from 'store/defaults';
import purge from 'store/purge';
import { emptyArr } from 'utilities/empties';

export default createSlice({
  name: 'SCHEDULES_ASSETS_DOMAIN',
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: {
      reducer: (state, { payload, meta }) => {
        state.isLoading = false;
        if (!payload) return;

        const { schid } = meta;
        const assets = payload.entities.assets;

        if (!assets) {
          state.data[schid] = emptyArr;
          return;
        }

        state.data[schid] = assets
          ? Object.keys(assets).map(key => parseInt(key))
          : state.data[schid];
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },

    updateSuccess: {
      reducer: (state, { payload, meta }) => {
        state.isLoading = false;
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_SCHEDULES_ASSETS = createAction('FETCH_SCHEDULES_ASSETS', (payload, meta) => ({
  payload,
  meta,
}));
export const UPDATE_SCHEDULES_ASSETS = createAction('UPDATE_SCHEDULES_ASSETS');
