import React from "react";
import { Title } from "styles/components";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router";
import ModalWrapper from "components/ModalWrapper/ModalWrapper";

export default function BadPermissions() {
  const history = useHistory();

  return (
    <ModalWrapper onClose={() => history.goBack()}>
      <Container>
        <Row>
          <Col>
            <Title>
              {i18n._(t`You do not have permission to view this page`)}
            </Title>
          </Col>
        </Row>
      </Container>
    </ModalWrapper>
  );
}
