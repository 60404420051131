import { takeLatest, put } from 'redux-saga/effects';
import slice, { CREATE_STRIPE_CHECKOUT_SESSION } from 'store/stripe/checkout/slice';
import { createStripeCheckoutSession } from 'resources/services/stripeService';

function* fetch({ payload, meta }) {
  const { setStripeCheckoutSession, fetching, error, success } = slice.actions;

  yield put(fetching());
  try {
    const res = yield createStripeCheckoutSession(payload);

    yield put(setStripeCheckoutSession(res));

    meta?.onSuccess && meta.onSuccess(res);
    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchCreateSubscription() {
  yield takeLatest(CREATE_STRIPE_CHECKOUT_SESSION.type, fetch);
}
