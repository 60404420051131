import { createSlice, createAction } from "@reduxjs/toolkit";
import purge from "store/purge";

const initialState = {
  isLoading: false,
  data: {},
};

export default createSlice({
  name: "DEVICE",
  initialState,
  reducers: {
    fetching: (state) => {
      state.isLoading = true;
    },

    error: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    success: (state) => {
      state.isLoading = false;
    },

    setDeviceInfo: (state, { payload }) => {
      state.data = payload;
    },

    clearError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const REGISTER_DEVICE = createAction(
  "REGISTER_DEVICE",
  (payload, meta) => ({
    payload,
    meta,
  })
);
