import {
  fleetbridgeBootstrap,
  mitrailBootstrap,
} from "styles/companies/bootstrapOverrides";

import fleetbridgeLogo from "assets/logos/fleetbridge_logo_dark.png";
import fleetbridgeLogoLight from "assets/logos/fleetbridge_logo_light.png";

import bluefoxLogo from "assets/logos/bluefoxlogo.png";
import bluefoxLogoLight from "assets/logos/bluefoxLogoLight.png";

import safetracksgpsLogo from "assets/logos/SafeTracks_Logo_Blue_Green_PNG.png";
import safetracksgpsLogoLight from "assets/logos/safetracksgps_logo_light.png";

import mitrail_trademark from "assets/logos/miTrailLogoTrademark.png";

import canadreamLogo from "assets/logos/CanaDream_logo.png";
import canadreamLogo_rounded from "assets/logos/canadream-rounded.png";

import mitrail_favicon from "assets/favicons/mitrail.ico";
import canadream_favicon from "assets/favicons/canadream.ico";
import fleetbridge_favicon from "assets/favicons/fleetbridge.ico";
import bluefox_favicon from "assets/favicons/bluefox.ico";
import safetracks_favicon from "assets/favicons/safetracks.ico";

const defaults = {
  bootstrap: fleetbridgeBootstrap,
  logo: fleetbridgeLogo,
  logo_light: fleetbridgeLogoLight,
  favicon: fleetbridge_favicon,
};

export default {
  bluefox: {
    ...defaults,
    logo: bluefoxLogo,
    logo_light: bluefoxLogoLight,
    favicon: bluefox_favicon,
  },

  safetracksgps: {
    ...defaults,
    logo: safetracksgpsLogo,
    logo_light: safetracksgpsLogoLight,
    favicon: safetracks_favicon,
  },

  canadream: {
    ...defaults,
    logo: canadreamLogo,
    logo_light: canadreamLogo_rounded,
    favicon: canadream_favicon,
  },

  mitrail: {
    ...defaults,
    bootstrap: mitrailBootstrap,
    logo: mitrail_trademark,
    logo_light: mitrail_trademark,
    favicon: mitrail_favicon,
  },

  fleetbridge: defaults,
};
