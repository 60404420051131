import { takeLatest, put } from 'redux-saga/effects';
import slice, { UPDATE_STRIPE_SUBSCRIPTION_ITEM } from 'store/stripe/subscriptionItems/slice';
import { updateStripeSubscriptionItem } from 'resources/services/stripeService';

function* fetch({ payload, meta }) {
  const { id, subscriptionItem } = payload;
  const { fetching, error, success } = slice.actions;

  yield put(fetching());
  try {
    const res = yield updateStripeSubscriptionItem(id, subscriptionItem);

    meta?.onSuccess && meta.onSuccess(res);

    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchUpdateSubscriptionItems() {
  yield takeLatest(UPDATE_STRIPE_SUBSCRIPTION_ITEM.type, fetch);
}
