import { createSlice, createAction } from "@reduxjs/toolkit";
import purge from "store/purge";

const initialState = {
  stripePaymentMethods: [],

  isLoading: false,
};

export default createSlice({
  name: "STRIPE_PAYMENT_METHODS",
  initialState,
  reducers: {
    fetching: (state) => {
      state.isLoading = true;
    },

    error: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    success: (state) => {
      state.isLoading = false;
    },

    addStripePaymentMethod: (state, { payload }) => {
      state.stripePaymentMethods.push(payload);
    },

    setStripePaymentMethods: (state, { payload }) => {
      state.stripePaymentMethods = payload;
    },

    setStripeCheckoutSession: (state, { payload }) => {
      state.stripeCheckoutSession = payload;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const FETCH_STRIPE_PAYMENT_METHODS = createAction(
  "FETCH_STRIPE_PAYMENT_METHODS"
);
export const CREATE_STRIPE_PAYMENT_METHOD = createAction(
  "CREATE_STRIPE_PAYMENT_METHOD",
  (payload, meta) => ({ payload, meta })
);
export const DELETE_STRIPE_PAYMENT_METHOD = createAction(
  "DELETE_STRIPE_PAYMENT_METHOD"
);
