import { takeLatest, fork } from 'redux-saga/effects';
import scheduleAssetsSlice, { FETCH_SCHEDULES_ASSETS, UPDATE_SCHEDULES_ASSETS } from './slice';
import schema from './schema';
import requestSaga from 'store/entities/xEntityRequest';

function* watchFetch() {
  yield takeLatest(FETCH_SCHEDULES_ASSETS.type, requestSaga, {
    actions: scheduleAssetsSlice.actions,
    schema,
  });
}

function* watchUpdate() {
  yield takeLatest(UPDATE_SCHEDULES_ASSETS.type, requestSaga, {
    actions: scheduleAssetsSlice.actions,
    success: scheduleAssetsSlice.actions.updateSuccess,
  });
}

export default function* scheduleAssetsDomainSagas() {
  yield fork(watchFetch);
  yield fork(watchUpdate);
}
