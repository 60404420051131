import { takeLatest, put } from 'redux-saga/effects';
import slice, { UPDATE_STRIPE_CUSTOMER } from 'store/stripe/customer/slice';
import { updateStripeCustomer } from 'resources/services/stripeService';

function* fetch({ payload }) {
  const { id, updatedCustomer } = payload;
  const { setStripeCustomer, fetching, error, success } = slice.actions;
  //TODO: add billing details from payment here

  yield put(fetching());
  try {
    const stripeCustomer = yield updateStripeCustomer(id, updatedCustomer);

    yield put(setStripeCustomer(stripeCustomer));
    yield put(success());
  } catch (e) {
    // console.error(e);
    yield put(error(e));
  }
}

export default function* watchUpdateCustomer() {
  yield takeLatest(UPDATE_STRIPE_CUSTOMER.type, fetch);
}
