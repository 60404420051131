import { createSlice } from "@reduxjs/toolkit";
import purge from "store/purge";
import datePeriodOptions from "components/Select/options/datePeriodOptions";

// NOTE: default period is set as 'today'
const defaultPeriod = datePeriodOptions()[0];

const defaultState = {
  shareTripModal: {
    open: false,
    shareTripURL: null,
    isLoading: false,
  },
  currentTripId: undefined,
  tripPolylineEnabled: false,
  period: defaultPeriod,
};

export default createSlice({
  name: "TRIPS_UI",
  initialState: defaultState,
  reducers: {
    setCurrentTripId: (state, { payload }) => {
      state.currentTripId = payload;
    },
    showTripPolyline: (state, { payload }) => {
      state.tripPolylineEnabled = !!payload;
    },
    setPeriod: (state, { payload }) => {
      state.period = payload;
    },
    setShareTripModal: (state, { payload }) => {
      state.shareTripModal = { ...state.shareTripModal, ...payload };
    },
  },
  extraReducers: {
    ...purge(defaultState),
  },
});
