import client from "configurations/fetch";

export const getAvailableSolutionPlans = (did) => {
  return client.get(`/SolutionPlans/device/${did}`);
};

export const getCurrentSolutionPlan = (did) => {
  return client.get(`/SolutionPlans/device/${did}/current`);
};

export const getAllSolutionPlansForCompany = (cid) => {
  return client.get(`/SolutionPlans/company/${cid}`);
};
