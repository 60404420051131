import { createSlice } from "@reduxjs/toolkit";
import purge from "store/purge";

const defaultState = {
  data: {},
};

export default createSlice({
  name: "DEVICES_UI",
  initialState: defaultState,
  reducers: {
    setDeviceData: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: {
    ...purge(defaultState),
  },
});
