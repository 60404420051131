import { takeLatest, put } from "redux-saga/effects";
import slice, { REMOVE_CART_ITEM } from "store/stripe/cart/slice";

function* remove({ payload: { aid, price, onSuccess } }) {
  const { removeItemFromCart, error } = slice.actions;

  try {
    yield put(removeItemFromCart({ aid, price }));
    onSuccess && onSuccess();
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchRemoveCartItem() {
  yield takeLatest(REMOVE_CART_ITEM.type, remove);
}
