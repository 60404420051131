import { fork } from "redux-saga/effects";
import watchSchedules from "./entities/schedules/sagas";
import watchAssets from "./entities/assets/sagas";
import watchFavourites from "./entities/favourites/sagas";
import watchAssetGroups from "./entities/groups/sagas";
import watchUsers from "./entities/users/sagas";
import watchGeoplaces from "./entities/geoplaces/sagas";
import watchScheduledReports from "./entities/scheduledReports/sagas";
import watchFetchReports from "./entities/reports/saga";
import watchAccount from "./account/sagas";
import watchCompanies from "./entities/companies/sagas";
import watchTrips from "./entities/trips/sagas";
import watchDeviceEvents from "./entities/deviceEvents/sagas";
import watchDevices from "./entities/devices/sagas";
import { watchStripe } from "./stripe";
import { watchRegisterDevice } from "./device/saga";

export default function* rootSaga() {
  // just FETCH
  yield fork(watchFetchReports);
  yield fork(watchAssetGroups);
  yield fork(watchAccount);
  yield fork(watchTrips);
  yield fork(watchDeviceEvents);
  yield fork(watchDevices);
  yield fork(watchStripe);

  // company CRUD
  yield fork(watchCompanies);
  // geoplaces CRUD
  yield fork(watchGeoplaces);
  // users CRUD
  yield fork(watchUsers);
  // favourites CRUD
  yield fork(watchFavourites);
  // assets CRUD
  yield fork(watchAssets);
  // scheduled reports CRUD
  yield fork(watchScheduledReports);
  // schedules CRUD
  yield fork(watchSchedules);

  // CUSTOM
  yield fork(watchRegisterDevice);
}
