import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultEntityReducers, defaultEntityState } from 'store/defaults';
import purge from 'store/purge';
import { emptyObj } from 'utilities/empties';

export default createSlice({
  name: 'MAINTENANCE_ALERTS_DOMAIN',
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload ? payload.entities.maintenanceAlerts || emptyObj : emptyObj;
    },

    updateSuccess: state => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_MAINTENANCE_ALERTS = createAction('FETCH_MAINTENANCE_ALERTS');
export const DELETE_MAINTENANCE_ALERT = createAction('DELETE_MAINTENANCE_ALERT');
export const ADD_MAINTENANCE_ALERT = createAction('ADD_NMAINTENANCE_ALERT');
export const UPDATE_MAINTENANCE_ALERT = createAction('UPDATE_MAINTENANCE_ALERT');
