import { createSlice, createAction } from '@reduxjs/toolkit';
import { defaultEntityReducers, defaultEntityState } from 'store/defaults';
import purge from 'store/purge';
import { emptyObj } from 'utilities/empties';

export default createSlice({
  name: 'NOTIFICATION_EVENTS_DOMAIN',
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload ? payload.entities.notificationEvents || emptyObj : emptyObj;
    },

    updateSuccess: state => {
      state.isLoading = false;
    },
  },
  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_NOTIFICATION_EVENTS = createAction('FETCH_NOTIFICATION_EVENTS');
export const DELETE_NOTIFICATION_EVENT = createAction('DELETE_NOTIFICATION_EVENT');
export const ADD_NOTIFICATION_EVENT = createAction('ADD_NOTIFICATION_EVENT');
export const UPDATE_NOTIFICATION_EVENT = createAction('UPDATE_NOTIFICATION_EVENT');
