import { useEffect } from "react";
import useLogout from "hooks/account/useLogout";
import accountSlice from "store/account/slice";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";

export default function useNewSession() {
  const logout = useLogout();
  const dispatch = useDispatch();
  const { setAnonymousUserPass } = accountSlice.actions;
  const route = useRouteMatch("/anonymousUser/:username/:password");

  useEffect(() => {
    const asyncFn = async () => {
      if (
        window.localStorage.getItem("rememberMe") ||
        window.sessionStorage.getItem("setCompletedInitialLoad")
      )
        return;

      await logout();
      window.sessionStorage.setItem("setCompletedInitialLoad", true);
    };

    asyncFn();
  }, [logout]);

  // NOTE: this is used for logging in a anonymous user via asset sharing
  useEffect(() => {
    if (!route) return;
    sessionStorage.setItem("anonymousUserPass", route?.params?.password);
  }, [dispatch, route, setAnonymousUserPass]);
}
