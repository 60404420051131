import { createSlice, createAction } from "@reduxjs/toolkit";
import { defaultEntityReducers, defaultEntityState } from "store/defaults";
import purge from "store/purge";

export default createSlice({
  name: "POSITIONS_ASSETS_DOMAIN",
  initialState: defaultEntityState,
  reducers: {
    ...defaultEntityReducers,

    success: (state, { payload }) => {
      state.isLoading = false;

      const positions =
        payload && payload.entities.positions ? payload.entities.positions : {};
      const positionsAsArray = Object.values(positions);

      // TODO: show the user that there are no positions?

      const filteredPositions = positionsAsArray
        .filter((position) => position.locationTypeString !== "InvalidPosition")
        .reduce((acc, position) => {
          acc[position.lid] = position;
          return acc;
        }, {});

      state.data =
        Object.keys(filteredPositions).length > 0
          ? filteredPositions
          : state.data;
    },

    clearPositionsById: (state, { payload }) => {
      state.data = Object.entries(state.data).reduce((acc, [key, val]) => {
        if (val.aid === payload) return acc;

        acc[key] = val;

        return acc;
      }, {});
    },

    clearPositions: () => defaultEntityState,
  },

  extraReducers: {
    ...purge(defaultEntityState),
  },
});

export const FETCH_POSITIONS = createAction("FETCH_POSITIONS");
