import { takeLatest, put, select } from "redux-saga/effects";
import accountSlice, { UPDATE_TUTORIALS } from "../slice";
import { updateUserTutorials } from "resources/services/userService";
import { selectMyUser } from "../selectors";

function* update({ payload, meta }) {
  const { error, setMyUser } = accountSlice.actions;

  const { uid, tutorials } = yield select(selectMyUser);

  try {
    const user = yield updateUserTutorials(uid, { ...tutorials, ...payload });

    yield put(setMyUser(user));
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchUpdateTutorials() {
  yield takeLatest(UPDATE_TUTORIALS.type, update);
}
