import { takeLatest, fork } from "redux-saga/effects";
import assetSlice, {
  UPDATE_ASSET,
  FETCH_ASSETS,
  SHARE_ASSET,
  UPDATE_ASSET_VEHICLE_ODOMETER,
  UPDATE_ASSET_ENGINE_HOURS,
} from "./slice";
import schema from "./schema";
import requestSaga from "store/entities/xEntityRequest";
import { selectAssets } from "./selectors";
import { watchDomains } from "./domains";

const actions = assetSlice.actions;

function* watchFetch() {
  yield takeLatest(FETCH_ASSETS.type, requestSaga, {
    cacheSelector: selectAssets,
    schema,
    actions,
  });
}

function* watchUpdate() {
  yield takeLatest(UPDATE_ASSET.type, requestSaga, { actions });
}

function* watchShare() {
  yield takeLatest(SHARE_ASSET.type, requestSaga, { actions });
}

function* watchUpdateOdometer() {
  yield takeLatest(UPDATE_ASSET_VEHICLE_ODOMETER.type, requestSaga, {
    actions,
  });
}

function* watchUpdateVehicleHours() {
  yield takeLatest(UPDATE_ASSET_ENGINE_HOURS.type, requestSaga, { actions });
}

export default function* assetSaga() {
  yield fork(watchFetch);
  yield fork(watchUpdate);
  yield fork(watchUpdateOdometer);
  yield fork(watchUpdateVehicleHours);
  yield fork(watchShare);

  yield fork(watchDomains);
}
