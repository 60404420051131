import { takeLatest, put, select } from "redux-saga/effects";
import slice, { UPDATE_MAP_SETTINGS } from "store/account/slice";
import { selectMyUser } from "store/account/selectors";
import { updateUserMapSettings } from "resources/services/userService";

function* update({ payload }) {
  const { request, onSuccess } = payload;
  const { error, success, setMapSettings } = slice.actions;
  const { uid } = yield select(selectMyUser);

  try {
    const response = yield updateUserMapSettings(request);

    if (response.error) {
      yield put(error(response.error));
      return;
    }

    yield put(setMapSettings(response));
    yield put(success());

    onSuccess && onSuccess(response);
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchUpdateMapSettings() {
  yield takeLatest(UPDATE_MAP_SETTINGS.type, update);
}
