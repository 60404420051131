/**
 * Sends the result of the mobile stepper to the device
 *
 * @param {string} result The result of stepper either 'success' or 'cancelled'.
 */
export const sendToMobileInterface = (result, params={}) => {
  if (window?.invokeCSharpAction) {
    const data = JSON.stringify({ result, params });
    window.invokeCSharpAction(data);
  }
};
