import { takeLatest, put } from 'redux-saga/effects';
import slice, { FETCH_STRIPE_PRICES } from 'store/stripe/prices/slice';
import { getStripePrices } from 'resources/services/stripeService';

function* fetch({ payload }) {
  const { setStripePrices, fetching, error, success } = slice.actions;

  yield put(fetching());
  try {
    const res = yield getStripePrices(payload);

    yield put(setStripePrices(res));
    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchFetchPrices() {
  yield takeLatest(FETCH_STRIPE_PRICES.type, fetch);
}
