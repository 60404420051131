import { takeLatest, put } from "redux-saga/effects";
import accountSlice, { ACCEPT_TRACKING_TERMS } from "store/account/slice";
import { acceptTrackingDeviceAgreement } from "resources/services/userService";

function* fetch({ payload, meta }) {
  const { uid, url } = payload;
  const { error, success, setMyUser } = accountSlice.actions;
  try {
    const response = yield acceptTrackingDeviceAgreement(uid, url);
    yield put(setMyUser(response));

    meta?.onSuccess && meta.onSuccess(response);
    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchAcceptTrackingTerms() {
  yield takeLatest(ACCEPT_TRACKING_TERMS.type, fetch);
}
