import React from "react";
import styled from "styled-components/macro";
import { bootstrap } from "styles/theme";

export function RippleSpinner({
  size = 36,
  color = bootstrap.dark,
  className,
}) {
  return (
    <StyledRippleSpinner className={className} size={size} color={color}>
      <div></div>
      <div></div>
    </StyledRippleSpinner>
  );
}

const StyledRippleSpinner = styled.div`
  display: inline-block;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  & div {
    position: absolute;
    border: 3px solid ${(props) => props.color};
    opacity: 1;
    border-radius: 50%;
    animation: ripple2 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  & div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes ripple2 {
    0% {
      top: ${(props) => props.size / 2}px;
      left: ${(props) => props.size / 2}px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: ${(props) => props.size}px;
      height: ${(props) => props.size}px;
      opacity: 0;
    }
  }
`;
