import { takeLatest, put, select } from "redux-saga/effects";
import accountSlice, { UPDATE_MY_COMPANY } from "store/account/slice";
import { updateCompany } from "resources/services/companyService";
import { selectMyCompany, selectMyUser } from "../selectors";

function* update({ payload, meta }) {
  const { error, success, setMyCompany } = accountSlice.actions;

  const { cid } = yield select(selectMyUser);
  const myCompany = yield select(selectMyCompany);

  try {
    const response = yield updateCompany(cid, { ...myCompany, ...payload });
    meta?.onSuccess && meta.onSuccess(response);

    yield put(setMyCompany(response));
    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchUpdateMyCompany() {
  yield takeLatest(UPDATE_MY_COMPANY.type, update);
}
