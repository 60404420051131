import { createSlice, createAction } from "@reduxjs/toolkit";
import purge from "store/purge";

const initialState = {
  latestInvoice: undefined,
  isLoading: false,
};

export default createSlice({
  name: "STRIPE_INVOICES",
  initialState,
  reducers: {
    fetching: (state) => {
      state.isLoading = true;
    },

    error: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    success: (state) => {
      state.isLoading = false;
    },

    setLatestInvoice: (state, { payload }) => {
      state.latestInvoice = payload;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const FETCH_LATEST_INVOICE = createAction("FETCH_LATEST_INVOICE");
