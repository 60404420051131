import { createSelector } from 'reselect';
import { emptyObj } from 'utilities/empties';

export const selectScheduledReports = state => state.entities.scheduledReports.data;
export const selectScheduledReportsAsArray = createSelector(
  [selectScheduledReports],
  scheduledReports => Object.values(scheduledReports)
);
export const selectIsLoadingScheduledReport = state => state.entities.scheduledReports.isLoading;
export const selectScheduledReportsError = state => state.entities.scheduledReports.error;

export const buildSelectScheduledReportById = srid =>
  createSelector([selectScheduledReports], scheduledReports => {
    return srid && scheduledReports ? scheduledReports[srid] : emptyObj;
  });
