import { createSlice, createAction } from '@reduxjs/toolkit';
import purge from 'store/purge';

const initialState = {
  stripeProducts: undefined,

  isLoading: false,
};

export default createSlice({
  name: 'STRIPE_PRODUCTS',
  initialState,
  reducers: {
    fetching: (state) => {
      state.isLoading = true;
    },

    error: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },

    success: (state) => {
      state.isLoading = false;
    },

    setStripeProducts: (state, { payload }) => {
      state.stripeProducts = payload;
    },
  },
  extraReducers: {
    ...purge(initialState),
  },
});

export const FETCH_STRIPE_PRODUCTS = createAction('FETCH_STRIPE_PRODUCTS');
