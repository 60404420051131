import { takeLatest, put } from 'redux-saga/effects';
import slice, { FETCH_STRIPE_PAYMENT_METHODS } from 'store/stripe/paymentMethods/slice';
import { getAllStripePaymentMethods } from 'resources/services/stripeService';

function* fetch({ payload }) {
  const { setStripePaymentMethods, fetching, error, success } = slice.actions;

  yield put(fetching());
  try {
    const { data } = yield getAllStripePaymentMethods(payload);

    yield put(setStripePaymentMethods(data));
    yield put(success());
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchFetchPaymentMethods() {
  yield takeLatest(FETCH_STRIPE_PAYMENT_METHODS.type, fetch);
}
