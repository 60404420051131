import { takeLatest, put } from "redux-saga/effects";
import accountSlice, { REGISTER } from "store/account/slice";
import { registerNewUser } from "resources/services/userService";
import getClientError from "utilities/getClientError";

function* fetch({ payload, meta }) {
  const { error, fetching, success } = accountSlice.actions;

  yield put(fetching());
  try {
    const response = yield registerNewUser(payload);

    if (response?.error) {
      throw new Error(response.error);
    }

    meta?.onSuccess && meta.onSuccess(response);

    yield put(success());
  } catch (e) {
    const message = getClientError(e);

    yield put(error(message));
    meta?.onError && meta.onError(message);
  }
}

export default function* watchRegister() {
  yield takeLatest(REGISTER.type, fetch);
}
