import { fork } from 'redux-saga/effects';
import watchScheduleAssetsDomain from './assets/sagas';
import watchScheduleGeoplacesDomain from './geoplaces/sagas';
import { combineReducers } from 'redux';
import assetsSlice from './assets/slice';
import geoplacesSlices from './geoplaces/slice';

export const domainReducers = combineReducers({
  assets: assetsSlice.reducer,
  geoplaces: geoplacesSlices.reducer,
});

export function* watchDomains() {
  yield fork(watchScheduleAssetsDomain);
  yield fork(watchScheduleGeoplacesDomain);
}
