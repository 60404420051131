import { fork } from "redux-saga/effects";
import watchFetchAvailableFirmwareList from "./fetchAvailableFirmwareList";
import watchFetchFirmwareConfig from "./fetchFirmwareConfig";
import watchFetchFirmwareUpdateProgress from "./fetchFirmwareUpdateProgress";
import watchRequestPollPosition from "./requestPollPosition";
import watchRequestRecoveryMode from "./requestRecoveryMode";
import watchUpdateDeviceFirmware from "./updateDeviceFirmware";

export default function* deviceDetailsSagas() {
  yield fork(watchUpdateDeviceFirmware);
  yield fork(watchFetchFirmwareConfig);
  yield fork(watchFetchAvailableFirmwareList);
  yield fork(watchFetchFirmwareUpdateProgress);
  yield fork(watchRequestPollPosition);
  yield fork(watchRequestRecoveryMode);
}
