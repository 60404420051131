import client from "configurations/fetch";

// CUSTOMERS

export const createStripeCustomer = (customer) => {
  return client.post("/stripe/customers/create", customer);
};

export const createStripeCustomerAsAdmin = (cid, customer) => {
  return client.post(`/stripe/customers/create/${cid}`, customer);
};

export const updateStripeCustomer = (customerId, updatedCustomer) => {
  return client.post(`/stripe/customers/update/${customerId}`, updatedCustomer);
};

// PAYMENT METHODS

export const updateStripeSubscriptionPaymentMethod = (
  subscriptionId,
  paymentMethodId
) => {
  return client.post(
    `/stripe/subscriptions/updatedefaultpaymentmethod/${subscriptionId}`,
    { id: paymentMethodId }
  );
};

export const getStripePaymentMethod = (paymentMethodId) => {
  return client.get(`/stripe/paymentmethods/get/${paymentMethodId}`);
};

export const getAllStripePaymentMethods = (customerId) => {
  return client.get(
    `/stripe/paymentmethods/getlist/?customer=${customerId}&type=card`
  );
};

export const createStripePaymentMethod = (paymentMethod) => {
  return client.post("/stripe/paymentmethods/create", paymentMethod);
};

export const updateStripePaymentMethod = (paymentMethod) => {
  return client.post(
    `/stripe/paymentmethods/update/${paymentMethod.id}`,
    paymentMethod
  );
};

export const deleteStripePaymentMethod = (paymentMethod) => {
  return client.post(`/stripe/paymentmethods/delete/${paymentMethod.id}`);
};

export const attachStripePaymentMethod = (paymentMethodId, customerId) => {
  return client.post(
    `/Stripe/PaymentMethods/Attach/${paymentMethodId}`,
    customerId
  );
};

export const detachStripePaymentMethod = (paymentMethodId) => {
  return client.post(`/Stripe/PaymentMethods/Detach/${paymentMethodId}`);
};

// CHECKOUT SESSIONS

export const getStripeCheckoutSession = (checkoutSessionId) => {
  return client.get(`/stripe/checkout/sessions/get/${checkoutSessionId}`);
};

export const getStripeCheckoutLineItems = (checkoutSessionId) => {
  return client.get(
    `/stripe/checkout/sessions/getlineitems/${checkoutSessionId}`
  );
};

export const createStripeCheckoutSession = (checkoutSession) => {
  return client.post(`/stripe/checkout/sessions/create`, checkoutSession);
};

// SUBSCRIPTIONS

export const getStripeSubscriptions = (id) => {
  return client.get(`/stripe/subscriptions/get/${id}`);
};

export const createStripeSubscription = (subscription) => {
  return client.post(`/stripe/subscriptions/create`, subscription);
};

export const updateStripeSubscription = (id, subscription, user) => {
  const query = user ? `?cid=${user.cid}&uid=${user.uid}` : "";
  return client.post(
    `/stripe/subscriptions/update/${id}${query}`,
    subscription
  );
};

export const cancelStripeSubscription = (id) => {
  return client.post(`/stripe/subscriptions/cancel/${id}`);
};

// SUBSCRIPTION ITEMS

export const getStripeSubscriptionItems = (subscriptionId) => {
  return client.get(
    `/stripe/subscriptionitems/getlist/?subscription=${subscriptionId}`
  );
};

export const createStripeSubscriptionItem = (subscriptionItem) => {
  return client.post(`/stripe/subscriptionitems/create`, subscriptionItem);
};

export const updateStripeSubscriptionItem = (id, subscriptionItem) => {
  return client.post(
    `/stripe/subscriptionitems/update/${id}`,
    subscriptionItem
  );
};

export const deleteStripeSubscriptionItem = (id) => {
  return client.delete(`/stripe/subscriptionitems/delete/${id}`);
};

// PRODUCTS

export const getStripeProducts = () => {
  return client.get(`/stripe/products/getactive`);
};

// PRICES

export const getStripePrices = () => {
  return client.get(`/stripe/prices/getactive`);
};

export const getStripeTaxRates = () => {
  return client.get("/stripe/taxrates/getactive");
};

// PAYMENT HISTORY

export const getStripePaymentHistory = (data) => {
  const { customerId, limit = 10 } = data;
  return client.get(
    `/stripe/paymentintents/getlist?customer=${customerId}&limit=${limit}`
  );
};

// INVOICES

export const getStripeInvoice = (invoiceId) => {
  return client.get(`/stripe/invoices/${invoiceId}`);
};
