import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";

export default function checkIsEmail(value) {
  const result = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );

  return !!result || i18n._(t`Must be a valid email address`);
}
