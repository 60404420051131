import styled from 'styled-components/macro';
import { Container, Card } from 'react-bootstrap';

export const _ContainerCard = styled(Container)`
  border-radius: 4px;
  background: white;
  box-shadow: rgba(82, 63, 105, 0.05) 0px 0px 13px 0px;
`;

export const _Card = styled(Card)`
  border-radius: 4px;
  background: white;
  box-shadow: rgba(82, 63, 105, 0.05) 0px 0px 13px 0px;
`;
