import { takeLatest, put } from 'redux-saga/effects';
import accountSlice, { FETCH_MY_AVATAR } from 'store/account/slice';
import { getMyAvatar } from 'resources/services/userService';

function* fetch() {
  const { error, setMyAvatar } = accountSlice.actions;

  try {
    const {
      data: { avatar },
    } = yield getMyAvatar();

    yield put(setMyAvatar(avatar));
  } catch (e) {
    yield put(error(e));
  }
}

export default function* watchFetchAvatar() {
  yield takeLatest(FETCH_MY_AVATAR.type, fetch);
}
