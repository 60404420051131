import { takeLatest, takeEvery, fork } from 'redux-saga/effects';
import usersSlice, { FETCH_USERS, UPDATE_USER, ADD_USER, DELETE_USER } from './slice';
import schema from './schema';
import entityRequestBuilder from 'store/entities/xEntityRequest';
import watchDomains from './domains';

const actions = usersSlice.actions;

function* watchFetch() {
  yield takeEvery(FETCH_USERS.type, entityRequestBuilder, {
    actions,
    schema,
  });
}

function* watchUpdate() {
  yield takeEvery(UPDATE_USER.type, entityRequestBuilder, { actions });
}

function* watchAdd() {
  yield takeLatest(ADD_USER.type, entityRequestBuilder, { actions });
}

function* watchDelete() {
  yield takeLatest(DELETE_USER.type, entityRequestBuilder, { actions });
}

export default function* userSaga() {
  yield fork(watchFetch);
  yield fork(watchUpdate);
  yield fork(watchAdd);
  yield fork(watchDelete);

  yield fork(watchDomains);
}
